<template>
    <div class="as">
      <div class="demo-basic--circle">
        <div class="block">
          <el-avatar :size="50" :src="circleUrl"></el-avatar>
        </div>
        <h4 style="display: inline-block;margin-left: 10px;font-weight: 400">{{ name }}</h4>
      </div>
      <el-button style="width: 100%;margin-top: 20px" size="medium" type="primary" @click="writearticle()">写文章</el-button>

      <ul>
        <router-link active-class="active" to="/framercenter/articlemanage">
          <li>
            <div class="aaa" style="width: 100px;margin: 0 auto">
              <i class="el-icon-notebook-2" style="float:left;line-height: 48px"></i>文章管理
            </div>
          </li>
        </router-link>
<!--        <router-link active-class="active" to="/framercenter/commontmanage">-->
<!--          <li>-->
<!--            <div class="aaa" style="width: 100px;margin: 0 auto">-->
<!--              <i class="el-icon-chat-dot-square" style="float:left;line-height: 48px"></i>评论管理-->
<!--            </div>-->
<!--          </li>-->
<!--        </router-link>-->
      </ul>
    </div>
</template>

<script>
import {getMyHead} from "@/api/userInformation";

export default {
  name: "ArticleSidebar",
  data() {
    return {
      circleUrl: require('../../assets/login.png'),
      name: ''
    }
  },
  methods: {
    writearticle() {
      this.$router.push('/framercenter/publisharticle')
    }
  },
  created() {
    getMyHead().then(
        res => {
          // console.log(res.data)
          this.circleUrl = res.data.head
          this.name = res.data.name
        }
    )
  }
}
</script>

<style scoped>
.as {
  /*position: fixed;*/
  padding: 20px 10px 10px 10px;
  width: 100%;
  height: 640px;
  background-color: #FFFFFF;
  border-radius: 5px;
}

.block {
  display: inline-block;
  vertical-align: middle;
}

a {
  text-decoration: none;
  color: #000000;
}

li {
  list-style: none;
  text-align: center;
  line-height: 48px;
}

li:hover {
  background-color: #F7F8FA;
  color: #1E80FF;
}

ul {
  margin-top: 20px;
}
.active{
  color: #1890FF;
}
</style>
