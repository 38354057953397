import request from "@/utils/request";

export function getTest() {
    return request({
        method: 'post',
        url: '/myArticle/api/v1/test'
    })
}

//获取文章类型
export function getArticleTypes() {
    return request({
        method: 'get',
        url: '/articleType/api/v1/getArticleTypes'
    })
}
//获取文章标签
export function getArticleTags() {
    return request({
        method: 'get',
        url: '/articleTag/api/v1/getArticleTags'
    })
}
//发布文章
export function publishArticle(article) {
    return request({
        method: 'post',
        url: '/myArticle/api/v1/publishArticle',
        data: article
    })
}

//根据状态查询本人文章列表
export function articleManageList(status,title) {
    return request({
        method: 'get',
        url: '/myArticle/api/v1/articleManageList',
        params: {
            status: status,
            title: title
        }
    })
}

//文章管理查询不同状态的文章数量
export function getCount() {
    return request({
        method: 'get',
        url: '/myArticle/api/v1/getCount'
    })
}

//根据文章id删除文章
export function delArtcleById(id) {
    return request({
        method: 'delete',
        url: '/myArticle/api/v1/delArticleById/'+id
    })
}

//根据文章id查询文章信息
export function getArtcleById(id) {
    return request({
        method: 'get',
        url: '/myArticle/api/v1/getArticleById/'+id
    })
}

//根据文章id查询标签
export function getTagByArticleId(id) {
    return request({
        method: 'get',
        url: '/articleTagRelation/api/v1/getTagByArticleId/'+id
    })
}

//点赞/取消赞
export function setArticleLike(id) {
    return request({
        method: 'post',
        url: '/articleLike/api/v1/setArticleLike',
        params: {
            articleId: id
        }
    })
}

//查询文章点赞数量
export function articleLikeCount(id) {
    return request({
        method: 'get',
        url: '/articleLike/api/v1/articleLikeCount',
        params: {
            articleId: id
        }
    })
}

//判断当前登录用户是否对某篇文章点赞
export function isLike(id) {
    return request({
        method: 'get',
        url: '/articleLike/api/v1/isLike',
        params: {
            articleId: id
        }
    })
}

//收藏/取消收藏文章
export function collectArticle(id) {
    return request({
        method: 'post',
        url: '/articleCollect/api/v1/collectArticle',
        params: {
            articleId: id
        }
    })
}

//查询文章收藏数量
export function articleCollectNumber(id) {
    return request({
        method: 'get',
        url: '/articleCollect/api/v1/articleCollectNumber',
        params: {
            articleId: id
        }
    })
}

//判断文章是否在该用户的收藏列表里
export function isCollect(id) {
    return request({
        method: 'get',
        url: '/articleCollect/api/v1/isCollect',
        params: {
            articleId: id
        }
    })
}

//查询文章浏览量
export function getArticleLookNum(id) {
    return request({
        method: 'get',
        url: '/myArticle/api/v1/getArticleLookNum',
        params: {
            articleId: id
        }
    })
}

//对文章评论
export function writeComment(articleComment) {
    return request({
        method: 'post',
        url: '/articleComment/api/v1/writeComment',
        data: articleComment
    })
}

//获取文章评论
export function listComments(articleId) {
    return request({
        method: 'get',
        url: '/articleComment/api/v1/listComments',
        params: {
            articleId: articleId
        }
    })
}

//获取某篇文章的评论总数
export function getCommentNumber(articleId) {
    return request({
        method: 'get',
        url: '/articleComment/api/v1/getCommentNumber',
        params: {
            articleId: articleId
        }
    })
}

//根据文章id修改文章
export function updateArticleById(myArticle) {
    return request({
        method: 'put',
        url: '/myArticle/api/v1/updateArticleById',
        data: myArticle
    })
}

//查询热门文章
export function hotArticleList() {
    return request({
        method: 'get',
        url: '/myArticle/api/v1/hotArticleList',
    })
}

//查询所有最新文章
export function newestListPage(page, size) {
    return request({
        method: 'get',
        url: '/myArticle/api/v1/newestListPage',
        params: {
            page: page,
            size: size
        }
    })
}

//查询所有最热文章
export function hottestListPage(page, size) {
    return request({
        method: 'get',
        url: '/myArticle/api/v1/hottestListPage',
        params: {
            page: page,
            size: size
        }
    })
}

//查询用户热门文章
export function personalHotList(userId) {
    return request({
        method: 'get',
        url: '/myArticle/api/v1/personalHotList',
        params: {
            userId: userId
        }
    })
}

//查询用户最新文章
export function personalNewList(userId) {
    return request({
        method: 'get',
        url: '/myArticle/api/v1/personalNewList',
        params: {
            userId: userId
        }
    })
}

//查询用户详情页头部信息
export function getUserAll(userId) {
    return request({
        method: 'get',
        url: '/myArticle/api/v1/getUserAll',
        params: {
            userId: userId
        }
    })
}

//查询本人收藏文章
export function getMyCollectArticle() {
    return request({
        method: 'get',
        url: '/articleCollect/api/v1/getMyCollectArticle'
    })
}

//取消文章收藏
export function qxCollect(id) {
    return request({
        method: 'put',
        url: '/articleCollect/api/v1/qxCollect',
        params: {
            articleId: id
        }
    })
}

//查询本人粉丝
export function getMyAttention() {
    return request({
        method: 'get',
        url: '/attention/api/v1/getMyAttention'
    })
}