<template>
  <div class="ps">
    <ul class="siderbar">
      <router-link active-class="active" :to="{name:'personaldata'}">
        <li>个人资料</li>
      </router-link>
<!--      <router-link active-class="active" :to="{name:'todolist'}">-->
<!--        <li>任务清单</li>-->
<!--      </router-link>-->
<!--      <router-link active-class="active" :to="{name:'commonutils'}">-->
<!--        <li>常用工具类</li>-->
<!--      </router-link>-->
<!--      <router-link active-class="active" :to="{name:'bugrecord'}">-->
<!--        <li>bug记录</li>-->
<!--      </router-link>-->
      <router-link active-class="active" :to="{name:'myCollect'}">
        <li>我的收藏</li>
      </router-link>
      <router-link active-class="active" :to="{name:'myFuns'}">
        <li>我的粉丝</li>
      </router-link>
      <router-link active-class="active" :to="{name:'articlemanage'}">
        <li>内容管理</li>
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PersonalSidebar",
  methods: {
  }
}
</script>

<style scoped>
.ps {
  position: fixed;
  float: left;
  width: 190px;
  padding: 10px 0;
  background-color: #FFFFFF;
  border-radius: 5px;
}
.siderbar li {
  list-style: none;
  text-align: center;
  line-height: 48px;
}
a {
  text-decoration: none;
  color: black;
}
.siderbar li:hover {
  background-color: #F0F0F5;
}
.active{
  color: #1890FF;
}
</style>
