<template>
  <div class="mr">
    <el-dialog
        title="欢迎注册"
        :visible.sync="dialogVisible"
        custom-class="saveAsDialog"
        :before-close="handleClose">
      <el-form :rules="rules" style="margin-top: 15px" ref="form" :model="form">
        <el-form-item prop="username">
          <el-input placeholder="请输入用户名" v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" placeholder="请输入密码" v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword">
          <el-input type="password" placeholder="请确认密码" v-model="form.confirmPassword"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button style="width: 100%" type="primary" @click="onSubmit(form)">立即注册</el-button>
          <div style="text-align: right">
            <el-link type="primary" @click="toLogin">已有账号？去登录</el-link>
            <!--          <router-link to="/welcome/register">没有账号？去注册</router-link>-->
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import bus from "@/store/bus";
import {register} from "@/api/juanbaUser";

export default {
  name: "MRegister",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.form.password !== '') {
          this.$refs.form.validateField('confirmPassword');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      form: {
        username: '',
        password: '',
        confirmPassword: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请确认密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    bus.$on('openRegister',val => {
      if(val) {
        this.dialogVisible = true
      }
    })
  },
  mounted() {
    document.addEventListener('keydown', this.handleWatchEnter);
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
          // eslint-disable-next-line no-unused-vars
          .then(_ => {
            this.dialogVisible = false
            this.form.username = ''
            this.form.password = ''
            this.form.confirmPassword = ''
            done();
          })
          // eslint-disable-next-line no-unused-vars
          .catch(_ => {});
    },
    handleWatchEnter(e) {
      var key = window.event ? e.keyCode : e.which;
      if (key === 13) {
        // 这里执行相应的行为动作
        this.onSubmit(this.form);
      }
    },
    toLogin() {
      this.form.username = ''
      this.form.password = ''
      this.form.confirmPassword = ''
      this.dialogVisible = false
      bus.$emit('openLogin',true)
    },
    onSubmit(formData) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // console.log(formData);
          //封装参数
          const juanbaUserDto = {
            userAccount: formData.username,
            userPassword: formData.password
          }
          //调用后台接口
          register(juanbaUserDto).then(
              res => {
                if(res.code === 200) {
                  this.$notify({
                    title: '成功',
                    message: '注册成功',
                    type: 'success'
                  });
                  //清空输入框
                  this.form.password = ''
                  this.form.username = ''
                  this.form.confirmPassword = ''
                  //关闭
                  this.dialogVisible = false
                  //登录
                  bus.$emit('openLogin',true)
                } else {
                  this.$notify.error({
                    title: '错误',
                    message: res.errorMessage
                  });
                }
              }
          )


        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
/deep/ .saveAsDialog {
  width: 400px !important;
}
</style>