<template>
  <div class="qa">
    <div class="center">
      <div class="add">
        <span style="color: rgba(0,0,0,.85);font-size: 14px">发布问题：</span>
        <el-button style="width: 100px;margin-left: 30px" size="small" type="primary" @click="dialogFormVisible = true">提问题</el-button>
      </div>
      <div class="search">
        <span style="color: rgba(0,0,0,.85);font-size: 14px">标签：</span>
        <el-select style="width: 310px" size="small" v-model="value" placeholder="可选择标签">
          <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <span style="color: rgba(0,0,0,.85);font-size: 14px;margin-left: 30px">内容：</span>
        <el-input size="small" style="width: 310px" v-model="likeContent" placeholder="请输入内容"></el-input>

        <div style="display: inline-block;float: right;margin-right: 80px">
          <el-button size="small" @click="chongzhi">重置</el-button>
          <el-button size="small" type="primary" @click="likeSelect">查询</el-button>
        </div>
      </div>

      <div class="list">
        <div class="showCard" v-for="question in questions" :key="question.id">
          <div class="headUser" style="padding: 10px">
            <div style="display: inline-block;vertical-align: middle" class="block"><el-avatar :size="40" :src="question.userHead"></el-avatar></div>
            <div style="display: inline-block;margin-left: 20px">
              <span style="font-size: 16px;color: rgba(0,0,0,.85)">{{ question.userName }}</span>
            </div>
            <span style="float: right;margin-right: 80px"><el-tag type="warning">回答 {{ question.commentNum }}</el-tag></span>
          </div>
          <div class="title" style="padding: 10px">
            <h5 class="hand" style="font-size: 16px;font-weight: 600" @click="toDetailQuestion(question.id)">{{ question.title }}</h5>
          </div>
          <div class="content" style="padding: 10px">
            <span style="font-size: 15px;color: rgba(0,0,0,.45)">
              {{ question.description }}
            </span>
          </div>
          <ul class="tag" style="padding: 10px">
            <li v-for="questionTag in question.questionTagList" :key="questionTag.id">
              <el-tag size="small" type="success">{{ questionTag.name }}</el-tag>
            </li>
<!--            <li><el-tag size="small" type="success">后端</el-tag></li>-->
          </ul>
          <div class="bottom" style="padding: 10px">
            <span style="color: rgba(0,0,0,.45);font-size: 14px">{{ question.createTime }}</span>
            <ul style="display: inline-block;margin-left: 30px">
              <li style="font-size: 14px"><i class="iconfont">&#xec7f;</i>
                <span v-if="question.likeNumber != null && question.likeNumber != ''"> {{ question.likeNumber }}</span>
                <span v-else> 0</span>
              </li>
              <li style="font-size: 14px"><i class="iconfont">&#xe626;</i>
                <span v-if="question.collectNumber != null && question.collectNumber != ''"> {{ question.collectNumber }}</span>
                <span v-else> 0</span>
              </li>
              <li style="font-size: 14px"><i class="iconfont">&#xe62d;</i>
                <span v-if="question.lookNumber != null && question.lookNumber != ''"> {{ question.lookNumber }}</span>
                <span v-else> 0</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
    <el-dialog title="发布问题" :visible.sync="dialogFormVisible" custom-class="saveAsDialog">
      <div>
        <span style="font-size: 16px">标题：</span>
        <el-input style="width: 600px" size="small" v-model="input" placeholder="请输入标题"></el-input>
      </div>
      <div style="margin-top: 20px">
        <span style="font-size: 16px">描述：</span>
        <el-input
            size="small"
            style="width: 600px;vertical-align: middle"
            type="textarea"
            autosize
            placeholder="请输入描述"
            v-model="textarea1">
        </el-input>
      </div>
      <div style="margin-top: 20px">
        <span style="font-size: 16px">标签：</span>
        <el-select size="small" style="width: 600px" v-model="value1" multiple placeholder="请选择标签">
          <el-option
              v-for="item in options1"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div style="margin-top: 20px">
        <span style="font-size: 16px">内容：</span>
        <div class="qe" style="border: 1px solid #ccc;margin-top: 10px">
          <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
          />
          <Editor
              style="min-height: 520px; overflow-y: hidden;"
              v-model="html"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
          />
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button style="float:left;" size="small" @click="initInput">重 置</el-button>
        <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="addQuestion">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import {publishQuestion, getTagList, getQuestionList} from "@/api/question";

export default {
  name: "QuestionAnswer",
  components: { Editor, Toolbar},
  data() {
    return {
      isOrNoLike: false,  //是否点赞
      isOrNoCollect: false, //是否收藏
      questions: [],
      options: [],
      options1: [],
      value: '',
      input: '',
      likeContent: '',
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      dialogFormVisible: false,
      textarea1: '',
      value1: [],
      editor: null,
      html: '',
      toolbarConfig: {
        excludeKeys: [
          '|',
          'group-indent',
          'todo',
          'undo',
          'redo'
        ]
        // insertKeys: {
        //   index: 35, // 插入的位置，基于当前的 toolbarKeys
        //   keys: ['createMenu']
        // }
        // toolbarKeys: [
        //   'codeBlock',
        // ]
      },
      editorConfig: {
        scroll: false,
        placeholder: '输入正文...（支持Markdown语法）',
      },
      mode: 'default', // or 'simple'
    }
  },
  methods: {
    addQuestion() {
      //参数校验
      if(this.input === null || this.input === '') {
        this.$notify({
          title: '警告',
          message: '问题标题不能为空',
          type: 'warning'
        });
        return;
      }
      if(this.textarea1 === null || this.textarea1 === '') {
        this.$notify({
          title: '警告',
          message: '问题描述不能为空',
          type: 'warning'
        });
        return;
      }
      if(this.value1.length === 0 || this.value1 === null || this.value1 === '') {
        this.$notify({
          title: '警告',
          message: '问题标签不能为空',
          type: 'warning'
        });
        return;
      }
      if(this.html === null || this.html === '' || this.html === '<p><br></p>') {
        this.$notify({
          title: '警告',
          message: '问题内容不能为空',
          type: 'warning'
        });
        return;
      }
      const question = {
        title: this.input,
        description: this.textarea1,
        content: this.html,
        questionTags: this.value1
      }
      publishQuestion(question).then(
          res => {
            this.getQuestions()
            // console.log(res)
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
            //清空输入框
            this.initInput()
          }
      )
      this.dialogFormVisible = false
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    getTags() {
      getTagList().then(
          res => {
            // console.log(res)
            this.options1 = res.data
            this.options = res.data
          }
      )
    },
    initInput() {
      this.input = ''
      this.textarea1 = ''
      this.html = ''
      this.value1 = []
    },
    chongzhi() {
      this.value = ''
      this.likeContent = ''
    },
    getQuestions() {
      getQuestionList(this.value,this.likeContent).then(
          res => {
            // console.log(res)
            this.questions = res.data;
          }
      )
    },
    likeSelect() {
      this.getQuestions()
      // this.chongzhi()
    },
    toDetailQuestion(questionId) {
      this.$router.push({
        name: 'detailQuestion',
        params: {
          id:questionId
        }
      })
    }
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  created() {
    // console.log(111)
    this.getQuestions();
    this.getTags();
  }
}
</script>

<style scoped>
.qa {
  padding: 10px;
  background-color: #F0F2F5;
  width: 100%;
}
.center {
  width: 1200px;
  /*background-color: #FFFFFF;*/
  margin: 0 auto;
  padding: 10px;
}
.search {
  width: 100%;
  background-color: #FFFFFF;
  padding: 20px;
}
.add {
  width: 100%;
  background-color: #FFFFFF;
  padding: 20px;
  margin-bottom: 10px;
}
.list {
  width: 100%;
  /*padding: 10px;*/
  /*margin-top: 10px;*/
}
.showCard {
  width: 100%;
  background-color: #FFFFFF;
  padding: 10px;
  margin-top: 10px;
}
.tag li {
  padding: 0 5px;
  display: inline-block;
  list-style: none;
}
.bottom li {
  padding: 0 20px;
  display: inline-block;
  list-style: none;
}
.hand:hover {
  cursor: pointer;
  color: #42A7FF;
}

/deep/ .saveAsDialog {
  width: 1200px !important;
}
</style>