<template>
  <div class="hot typeArea">
    <el-carousel class="zmd" height="400px">
      <el-carousel-item v-for="article in hotArticles" :key="article.id">
        <router-link class="showImg" :to="{
            name: 'detailpage',
            params: {
              id: article.id
            }
          }">
          <img :src="article.cover" class="image">
<!--          <span class="title">测试数据</span>-->
        </router-link>
      </el-carousel-item>
    </el-carousel>

<!--    <div class="hotlist">-->
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span><h2 style="display: inline-block;margin: 0 auto">热门文章推荐</h2></span>
<!--          <el-button style="float: right; padding: 3px 0" type="text"><h2>更多</h2></el-button>-->
        </div>
        <div v-for="article in hotArticles" :key="article.id" class="text item">
          <router-link :to="{
            name: 'detailpage',
            params: {
              id: article.id
            }
          }">
            <h2 style="font-size: 13px">
              {{article.title}}
            </h2>
          </router-link>

        </div>
      </el-card>
  </div>
</template>

<script>
import {hotArticleList} from "@/api/myArticle";

export default {
  name: "HotArticle",
  data() {
    return {
      hotArticles: [],
      items: ['https://pic3.zhimg.com/v2-58d652598269710fa67ec8d1c88d8f03_r.jpg?source=1940ef5c',
        'https://desk-fd.zol-img.com.cn/t_s960x600c5/g1/M0B/03/06/ChMljl402K6IOTZbAARWayFg6S4AAQJPwFhuRIABFaD752.jpg',
        'https://pic.3gbizhi.com/2019/0928/20190928012439343.jpg',
        'https://img.zcool.cn/community/01c8f15aeac135a801207fa16836ae.jpg@1280w_1l_2o_100sh.jpg']
    }
  },
  created() {
    hotArticleList().then(
        res => {
          this.hotArticles = res.data
          // console.log(this.hotArticles)
        }
    )
  }
}
</script>

<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.typeArea {
  width: 1380px;
  margin: 0 auto;
}

.hot {
  overflow: hidden;
  margin-top: 10px;
  padding: 10px;
  /*height: 400px;*/
  background-color: #FFFFFF;
}

.zmd {
  float: left;
  /*width: 650px;*/
  width: 1000px;
  border-radius: 5px;
}

.image {
  /*width: 650px;*/
  width: 1000px;
  height: 400px;
}

.showImg {
  display: block;
  position: relative;
}

.title {
  display: block;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000000;
  font-weight: 700;
  font-size: 20px;
}
ul {
  display: inline-block;
  width: 680px;
  height: 300px;
  margin-left: 20px;
  list-style: none;
  /*background-color: #1890FF;*/
}
li {
  padding: 0 10px 15px;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}

.box-card {
  text-align: center;
  display: inline-block;
  margin-left: 10px;
  width: 350px;
  height: 400px;
}
h1,h2{
  font-weight: 400;
  font-size: 20px;
}
a {
  text-decoration: none;
  color: #000000;
}
a:hover {
  color: #409EFF;
}
.hotlist {
  margin-left: 10px;
}
</style>
