<template>
  <div class="st typeArea">
    <ul>

        <li v-for="type in typeList" :key="type.id"><a href="#">{{type.name}}</a></li>

<!--      <a href="#"><li>学习成长</li></a>-->
<!--      <a href="#"><li>测试开发</li></a>-->
<!--      <a href="#"><li>后端</li></a>-->
<!--      <a href="#"><li>学习成长</li></a>-->
<!--      <a href="#"><li>测试开发</li></a><a href="#"><li>后端</li></a>-->
<!--      <a href="#"><li>学习成长</li></a>-->
<!--      <a href="#"><li>测试开发</li></a><a href="#"><li>后端</li></a>-->
<!--      <a href="#"><li>学习成长</li></a>-->
<!--      <a href="#"><li>学习成长</li></a>-->
<!--      <a href="#"><li>测试开发</li></a><a href="#"><li>后端</li></a>-->
<!--      <a href="#"><li>学习成长</li></a>-->
<!--      <a href="#"><li>学习成长</li></a>-->
<!--      <a href="#"><li>测试开发</li></a><a href="#"><li>后端</li></a>-->
<!--      <a href="#"><li>学习成长</li></a>-->
<!--      <a href="#"><li>学习成长</li></a>-->
<!--      <a href="#"><li>测试开发</li></a><a href="#"><li>后端</li></a>-->
<!--      <a href="#"><li>学习成长</li></a>-->
    </ul>
  </div>
</template>

<script>
import {getArticleTypes} from "@/api/myArticle";

export default {
  name: "ShowType",
  data() {
    return {
      typeList: []
    }
  },
  created() {
    getArticleTypes().then(
        res => {
          // console.log(res.data)
          this.typeList = res.data
        }
    )
  }
}
</script>

<style scoped>
.typeArea {
  width: 1380px;
  margin: 0 auto;
}
.st {
  background-color: #FFFFFF;
  padding: 14px 4px;
}
li {
  padding: 10px 24px 10px;
  display: inline-block;
  list-style: none;
}
a {
  text-decoration: none;
  color: #000000;
}
a:hover {
  color: #1890FF;
}
</style>
