<template>
  <div class="dh">
    <div class="content">
      <div class="left">
        <div class="demo-basic--circle">
          <div class="block" @click="dialogVisible=true">
            <el-tooltip class="item" effect="dark" content="点击修改头像" placement="right">
              <el-avatar :size="100" :src="circleUrl"></el-avatar>
            </el-tooltip>
          </div>
        </div>
        <h2>{{ nickName }}</h2>
        <p style="margin-top: 5px">加入卷吧时间：{{ createTime }}</p>
        <p style="margin-top: 5px">粉丝：{{ funsSum }}</p>
        <p style="margin-top: 5px">原创文章：{{ articleSum }}</p>
        <p style="margin-top: 5px">总访问量：{{ lookSum }}</p>
      </div>

      <div class="right">
        <div class="description">
          <h3>个人简介：</h3>
          <p v-if="introduction === ''">这个人很懒，什么都没留下</p>
          <p>{{ introduction }}</p>
        </div>
        <div class="tag">
          <h3>技能标签：</h3>
          <span v-if="myTags.length === 0">暂未设置标签</span>
          <ul>
            <li v-for="tag in myTags" :key="tag.id"><el-tag size="small">{{ tag.tag }}</el-tag></li>
          </ul>
        </div>

      </div>
      <el-dialog
          style="text-align: center"
          title="修改头像"
          :visible.sync="dialogVisible"
          width="30%">

        <div style="margin: 0 auto">
          <el-upload
              ref="upload"
              class="upload-demo"
              drag
              action="http://39.103.60.183:8081/myFile/api/v1/uploadFile"
              :headers="{authorization: token}"
              :on-success="handleAvatarSuccess"
              :limit="1"
              multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </div>

        <span slot="footer" class="dialog-footer">
    <el-button @click="sure">取 消</el-button>
    <el-button type="primary" @click="sure">确 定</el-button>
  </span>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import bus from "@/store/bus";
import {getToken} from "@/utils/tokenUtil";
import {updateHeadPortrait} from "@/api/userInformation";
import {getUserInformation} from "@/api/juanbaUser";

export default {
  name: "DataHeader",
  data() {
    return {
      circleUrl: "",
      nickName: '',
      introduction: '',
      myTags: [],
      dialogVisible: false,
      token: '',
      userId: '',
      createTime: '',
      funsSum: 0,
      articleSum: 0,
      lookSum: 0
    }
  },
  methods: {
    sure() {
      this.dialogVisible = false
      this.$refs.upload.clearFiles()
    },
    handleAvatarSuccess(res, file) {
      const id = this.userId
      // console.log('111',id)
      const filePath = file.response.data
      // console.log('filePath',filePath)
      updateHeadPortrait(id,filePath).then(
          res => {
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
            this.circleUrl = filePath
          }
      )
    },
  },
  created() {
    this.token = getToken('token')
    bus.$on('nickNameChange',res => {
      this.nickName = res
    })
    bus.$on('introductionChange', res => {
      this.introduction = res
    })
    bus.$on('myTagsChange', res => {
      this.myTags = res
    })
    bus.$on('userId',res => {
      this.userId = res
    })
    bus.$on('headPortrait', res => {
      this.circleUrl = res
    })
    getUserInformation().then(
        res => {
          // console.log(res)
          this.createTime = res.data.createTime
          this.articleSum = res.data.articleSum
          this.funsSum = res.data.funsNum
          this.lookSum = res.data.lookSum
        }
    )
  }
}
</script>

<style scoped>
.dh {
  border-radius: 5px;
  position: relative;
  height: 308px;
  background:url("https://i.hexuexiao.cn/up/f8/98/9d/6d56fbebd24177355962e92cc59d98f8.jpg.source.jpg") no-repeat;
  /*background-position: center;*/
  background-size: cover;
}
.content {
  position: absolute;
  padding: 24px 0 40px 24px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background:rgba(255,255,255,0.1);
  color: #FFFFFF;
}
.left {
  padding: 5px;
  width: 300px;
  height: 244px;
  float: left;
  /*background-color: skyblue;*/
  text-align: center;
}
.right {
  padding: 5px;
  width: 676px;
  height: 244px;
  float: left;
  /*background-color: #55a532;*/
}
.tag ul li {
  padding: 10px 5px;
  display: inline-block;
  list-style: none;
}
.description {
  padding: 0 20px 0 0;
  margin-top: 15px;
  height: 160px;
  /*background-color: #9a6e3a;*/
}
.description p {
  margin-top: 10px;
  text-indent: 2em;
}
.tag {
}
.block:hover {
  cursor:pointer;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
