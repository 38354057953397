<template>
  <div class="pb">
    <TypeArea></TypeArea>
  </div>
</template>

<script>
import TypeArea from "@/components/personalcenter/typearea/TypeArea";
export default {
  name: "PersonalCenter",
  components: {TypeArea}
}
</script>

<style scoped>
.pb {
  background-color: #F0F2F5;
  width: 100%;
}
</style>
