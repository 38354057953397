<template>

  <div class="ae" style="border: 1px solid #ccc;">
    <div class="title" style="width: 100%;height: 64px;padding: 0 5px 5px 20px;line-height: 64px">
      <input class="text" placeholder="请输入文章标题..." style="width: 100%;height: 100%;border: 0;font-size: 24px" v-model="title">
<!--      <el-input size="medium " style="width: 100%;height: 100%" placeholder="请输入文章标题..."></el-input>-->
    </div>
    <hr>
    <Toolbar
        style="border-bottom: 1px solid #ccc;"
        :editor="editor"
        :defaultConfig="toolbarConfig"
    />
    <Editor
        style="min-height: 520px; overflow-y: hidden;"
        v-model="html"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="onCreated"
        @onChange="onChange"
    />
  </div>
</template>

<script>
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import bus from "@/store/bus";
import {getArtcleById} from "@/api/myArticle";

export default {
  name: "ArticleEditor",
  components: {Editor, Toolbar},
  data() {
    return {
      editor: null,
      title: '',
      html: '',
      toolbarConfig: {
        excludeKeys: [
            '|',
            'group-indent',
            'todo',
            'fullScreen'
        ]
        // insertKeys: {
        //   index: 35, // 插入的位置，基于当前的 toolbarKeys
        //   keys: ['createMenu']
        // }
        // toolbarKeys: [
        //   'codeBlock',
        // ]
      },
      editorConfig: {
        scroll: false,
        placeholder: '输入正文...（支持Markdown语法）',
      },
      mode: 'default',
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      // editorConfig.scroll = false
    },
    onChange() {
      // console.log('text:',this.editor.getText())
      this.$store.commit('articlecontent', this.html)
      this.$store.commit('articletext', this.editor.getText())
    }
  },
  created() {
    // console.log(111)
    bus.$on("clear", val => {
      if (val)
        this.html = ''
    })
    bus.$on("cleartitleandcontent",val => {
      if(val) {
        this.title = ''
        this.html = ''
      }
    })
    const initArticleId = this.$route.params.id
    if(initArticleId != null && initArticleId != '')
      getArtcleById(initArticleId).then(
          res => {
            this.title = res.data.title
            this.html = res.data.content
            this.$store.commit('articletitle',res.data.title)
            this.$store.commit('articlecontent', res.data.content)
          }
      )
  },
  mounted() {
  },
  // activated() {
  //   // console.log(111)
  //   bus.$on("clear", val => {
  //     if (val)
  //       this.html = ''
  //   })
  //   bus.$on("cleartitleandcontent",val => {
  //     if(val) {
  //       this.title = ''
  //       this.html = ''
  //     }
  //   })
  //   const initArticleId = this.$route.params.id
  //   if(initArticleId != null && initArticleId != '')
  //   getArtcleById(initArticleId).then(
  //       res => {
  //         this.title = res.data.title
  //         this.html = res.data.content
  //         this.$store.commit('articletitle',res.data.title)
  //         this.$store.commit('articlecontent', res.data.content)
  //       }
  //   )
  // },
  // deactivated() {
    // console.log("beforeDestroy")
    // const editor = this.editor
    // if (editor == null) return
    // editor.destroy() // 组件销毁时，及时销毁编辑器
  // },
  watch: {
    title(val) {
      // console.log(val)
      this.$store.commit('articletitle',val)
    }
  },
  beforeDestroy() {
    // console.log("beforeDestroy")
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
}
</script>

<style scoped>
.ae {
  /*margin-left: 10px;*/
  width: 100%;
  background-color: #FFFFFF;
}
.text:focus {
  border: 0;
  outline: none;
}
/*.title>>>.el-input__inner {*/
/*  border: 0;*/
/*}*/
::v-deep input::-webkit-input-placeholder {
  font-size:24px;
  color: #86909C;
}
</style>
