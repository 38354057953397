<template>
  <div class="ld">
    <el-dialog
        title="欢迎登录"
        :visible.sync="dialogVisible"
        custom-class="saveAsDialog"
        :before-close="handleClose">
      <el-form :rules="rules" style="margin-top: 15px" ref="form" :model="form">
        <el-form-item prop="username">
          <el-input placeholder="请输入用户名" v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" placeholder="请输入密码" v-model="form.password"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button style="width: 100%" type="primary" @keyup.enter="onSubmit(form)" @click="onSubmit(form)">立即登录
          </el-button>
          <div style="text-align: right">
            <el-link type="primary" @click="toRegister">没有账号？去注册</el-link>
            <!--          <router-link to="/welcome/register">没有账号？去注册</router-link>-->
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

import bus from "@/store/bus";
import {login} from "@/api/juanbaUser";
import {setToken} from "@/utils/tokenUtil";

export default {
  name: "MLogin",
  data() {
    return {
      dialogVisible: false,
      form: {
        username: '',
        password: '',
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ]
      }
    }
  },
  created() {
    bus.$on('openLogin',val => {
      if(val) {
        this.dialogVisible = true
      }
    })
  },
  mounted() {
    document.addEventListener('keydown', this.handleWatchEnter);
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
          // eslint-disable-next-line no-unused-vars
          .then(_ => {
            this.dialogVisible = false
            this.form.username = ''
            this.form.password = ''
            done();
          })
          // eslint-disable-next-line no-unused-vars
          .catch(_ => {
          });
    },
    handleWatchEnter(e) {
      var key = window.event ? e.keyCode : e.which;
      if (key === 13) {
        // 这里执行相应的行为动作
        this.onSubmit(this.form);
      }
    },
    onSubmit(formData) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // console.log(formData);
          //封装参数
          const juanbaUserDto = {
            userAccount: formData.username,
            userPassword: formData.password
          }
          //请求后台接口
          login(juanbaUserDto).then(
              res => {
                console.log(res)
                if(res.code === 200) {
                  this.$notify({
                    title: '成功',
                    message: '登录成功',
                    type: 'success'
                  });
                  //将token存入本地
                  setToken(res.data)
                  //清空输入框
                  this.form.password = ''
                  this.form.username = ''
                  //刷新页面
                  this.$router.go(0)
                  //关闭
                  this.dialogVisible = false
                } else {
                  this.$notify.error({
                    title: '错误',
                    message: res.errorMessage
                  });
                }
              }
          )
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    toRegister() {
      this.form.username = ''
      this.form.password = ''
      this.dialogVisible = false
      bus.$emit('openRegister',true)
    },
  }
}
</script>

<style scoped>
/deep/ .saveAsDialog {
  width: 400px !important;
}
</style>