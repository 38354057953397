<template>
  <div class="du">
    <div class="center">
      <div class="desc">
        <h1 style="margin-bottom: 10px;font-size: 28px">工具类作用描述：</h1>
        <div style="background-color: #F0F2F5;padding: 10px;border-radius: 4px;margin-bottom: 30px" class="div">
          <p>{{utilDescription}}</p>
        </div>

      </div>
      <div class="code">
        <h1 style="font-size: 28px;margin-bottom: 10px">工具类代码如下：</h1>
        <div class="article">
          <Editor
              style="min-height: 10px; overflow-y: hidden;"
              v-model="utilCode"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Editor } from '@wangeditor/editor-for-vue'
export default {
  name: "DetailUtil",
  components: { Editor },
  data() {
    return {
      utilDescription: '',
      utilCode: '',
      editor: null,
      toolbarConfig: { },
      editorConfig: {
        placeholder: '',
        scroll: false,
        readOnly: true
      },
      mode: 'default', // or 'simple'
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  created() {
    this.utilDescription = this.$store.state.utilDescription
    this.utilCode = this.$store.state.utilCode
    // console.log('utilDescription',this.$store.state.utilDescription)
    // console.log('utilCode',this.$store.state.utilCode)
  }
}
</script>

<style scoped>
.du {
  padding: 10px;
  overflow: hidden;
  background-image: url("https://pic.52112.com/180308/180308_94/4S0JMaqsnM_small.jpg");
  width: 100%;
}
.center {
  padding: 20px;
  margin: 0 auto;
  width: 1200px;
  background-color: white;
  border-radius: 4px;
}
</style>