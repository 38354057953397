<template>
  <div class="mf">
    <div class="show" style="margin-top: 20px">
      <el-table
          :data="tableData"
          style="width: 100%">
        <el-table-column
            prop="userInformation.headPortrait"
            label="头像"
            align="center"
            :width="160"
        >
          <template slot-scope="scope">
            <img :src="scope.row.userInformation.headPortrait" alt="" style="width: 100%;height: 70px">
          </template>
        </el-table-column>
<!--        <el-table-column-->
<!--            prop="userAccount"-->
<!--            label="账号"-->
<!--            align="center"-->
<!--        >-->
<!--        </el-table-column>-->
        <el-table-column
            prop="userInformation.nickName"
            label="昵称"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="userInformation.userSex"
            label="性别"
            align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.userInformation.userSex=='NAN'" type="success">男</el-tag>
            <el-tag v-if="scope.row.userInformation.userSex=='NV'" type="success">女</el-tag>
            <el-tag v-if="scope.row.userInformation.userSex=='NULL'" type="info">暂未设置</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="userInformation.school"
            label="学校"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.userInformation.school==null" type="info">暂未设置</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="userInformation.company"
            label="公司"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.userInformation.company==null" type="info">暂未设置</el-tag>
          </template>
        </el-table-column>
<!--        <el-table-column-->
<!--            prop="status"-->
<!--            label="状态"-->
<!--            align="center">-->
<!--          <template slot-scope="scope">-->
<!--            <el-tag v-if="scope.row.status==false" type="success">正常</el-tag>-->
<!--            <el-tag v-if="scope.row.status==true" type="info">禁用</el-tag>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column
            align="center"
            label="标签">
          <template slot-scope="scope">
            <template>
              <el-tag
                  size="small"
                  style="margin-left: 3px"
                  type="success"

                  :key="index"
                  v-for="(item, index) in scope.row.userTags"
              >{{item.tag}}</el-tag>
              <el-tag v-if="scope.row.userTags==null" type="info">暂未设置</el-tag>
            </template>
          </template>
        </el-table-column>
<!--        <el-table-column-->
<!--            prop="createTime"-->
<!--            align="center"-->
<!--            label="创建时间">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--            fixed="right"-->
<!--            label="操作"-->
<!--            align="center"-->
<!--            width="100">-->
<!--          <template slot-scope="scope">-->
<!--            <el-button-->
<!--                size="mini"-->
<!--                type="warning"-->
<!--                round-->
<!--                @click="handleEdit(scope.$index, scope.row)">回关</el-button>-->
<!--            <el-button-->
<!--                size="mini"-->
<!--                type="warning"-->
<!--                round-->
<!--                @click="handleDelete(scope.$index, scope.row)">取消关注</el-button>-->

<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
    </div>
  </div>
</template>

<script>
import {getMyAttention} from "@/api/myArticle";

export default {
  name: "MyFuns",
  data() {
    return {
      tableData: [],
    }
  },
  methods: {
    getAttentionList() {
      getMyAttention().then(
          res => {
            // console.log(res)
            this.tableData = res.data
          }
      )
    }
  },
  created() {
    this.getAttentionList()
  }
}
</script>

<style scoped>
.mf {
  float: right;
  /*margin-left: 10px;*/
  width: 1000px;
  background-color: #FFFFFF;
  padding: 10px;
}
</style>