import request from "@/utils/request";

//得到登录用户的资料信息
export function getMySelfInformation() {
    return request({
        method: 'get',
        url: '/userinformation/api/v1/getMySelfInformation'
    })
}

//修改资料信息
export function updateMySelfInformation(userInformation) {
    return request({
        method: 'put',
        url: '/userinformation/api/v1/updateMySelfInformation',
        data: userInformation
    })
}

//根据用户id查询昵称
export function getNickNameById(id) {
    return request({
        method: 'get',
        url: '/userinformation/api/v1/getNickNameById/'+id,
    })
}

//修改用户头像
export function updateHeadPortrait(userId,filePath) {
    return request({
        method: 'post',
        url: '/userinformation/api/v1/updateHeadPortrait/'+userId,
        params: {
            filePath: filePath
        }
    })
}

//查询本人头像
export function getMyHead() {
    return request({
        method: 'get',
        url: '/userinformation/api/v1/getMyHead'
    })
}