import request from "@/utils/request";

// 获取本人技能标签
export function getMySelfTags() {
    return request({
        method: 'get',
        url: '/usertagrelation/api/v1/getMySelfTags'
    })
}

//获取所有技能标签
export function getAllTags() {
    return request({
        method: 'get',
        url: '/usertag/api/v1/getAllTags'
    })
}

//设置标签
export function setUserTagRelation(tagIds) {
    return request({
        method: 'post',
        url: '/usertagrelation/api/v1/setUserTagRelation',
        data: tagIds
    })
}