import request from "@/utils/request";

// 关注/取消用户
export function attentionUser(beFollowerId) {
    return request({
        method: 'post',
        url: '/attention/api/v1/attentionUser',
        params: {
            beFollowerId: beFollowerId
        }
    })
}

//查询粉丝数量
export function userAttentionNum(userId) {
    return request({
        method: 'get',
        url: '/attention/api/v1/userAttentionNum',
        params: {
            userId: userId
        }
    })
}

//判断是否关注某个用户
export function isAttention(beFollowerId) {
    return request({
        method: 'get',
        url: '/attention/api/v1/isAttention',
        params: {
            beFollowerId: beFollowerId
        }
    })
}