var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hot typeArea"},[_c('el-carousel',{staticClass:"zmd",attrs:{"height":"400px"}},_vm._l((_vm.hotArticles),function(article){return _c('el-carousel-item',{key:article.id},[_c('router-link',{staticClass:"showImg",attrs:{"to":{
            name: 'detailpage',
            params: {
              id: article.id
            }
          }}},[_c('img',{staticClass:"image",attrs:{"src":article.cover}})])],1)}),1),_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_c('h2',{staticStyle:{"display":"inline-block","margin":"0 auto"}},[_vm._v("热门文章推荐")])])]),_vm._l((_vm.hotArticles),function(article){return _c('div',{key:article.id,staticClass:"text item"},[_c('router-link',{attrs:{"to":{
            name: 'detailpage',
            params: {
              id: article.id
            }
          }}},[_c('h2',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(article.title)+" ")])])],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }