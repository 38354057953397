<template>
  <div class="al typeArea">
    <ul class="first">
      <el-button type="text" @click="newest">
        <li style="font-size: 16px;color: #000000"><i class="el-icon-star-off"></i>最新</li>
      </el-button>
      <el-button type="text" @click="hottest">
        <li style="font-size: 16px;color: #000000"><i class="el-icon-star-off"></i>最热</li>
      </el-button>
    </ul>
    <hr class="fgx">
    <div class="list">
      <ul class="second">
        <li v-for="article in articleList" :key="article.id">
          <el-card class="card" shadow="never">
            <h3 class="hand" style="margin-left: 5px" @click="xiangqing(article.id)">{{ article.title }}</h3>
            <div class="content">
              <div style="width: 134px;height: 84px;display: inline-block" class="simg hand" @click="xiangqing(article.id)">
                <img style="width: 134px;height: 84px;border-radius: 5px;display: inline-block" :src="article.cover">
              </div>
              <div style="width: 800px;height: 84px;display: inline-block;float: right" class="cr">
                <p style="width: 800px" class="description">{{ article.articleAbstract }}
                </p>
                <ul class="three">
                  <span>
                    <li>
                    <div style="margin-top: 15px;height: 40px;" class="demo-basic--circle avatar">
                      <el-avatar style="vertical-align: middle" :size="40" :src="article.userInformation.headPortrait"></el-avatar>
                    </div>
                  </li>
                  <li>
                    <span>{{ article.userInformation.nickName }}</span>
                  </li>
                  <li v-for="tag in article.articleTags" :key="tag.id">
                    <el-tag size="small" style="margin-left: 5px">{{ tag.name }}</el-tag>
                  </li>
                  </span>

                  <span style="float: right;margin-top: 23px">
                    <li>
                    <i style="color: #42A7FF" class="iconfont">&#xe69a;</i>
                    <span> {{ article.lookNumber }}</span>
                    <span v-if="article.lookNumber === null || article.lookNumber === ''"> 0</span>
                  </li>
                  <li style="margin-left: 10px">
                    <i style="color: #42A7FF" class="iconfont">&#xe636;</i>
                    <span> {{ article.collectNumber }}</span>
                    <span v-if="article.collectNumber === null || article.collectNumber === ''"> 0</span>
                  </li>
                  <li style="margin-left: 10px">
                    <i style="color: #42A7FF" class="iconfont">&#xec8c;</i>
                    <span> {{ article.likeNumber }}</span>
                    <span v-if="article.likeNumber === null || article.likeNumber === ''"> 0</span>
                  </li>
                  <li style="margin-left: 10px">
                    <i style="color: #42A7FF" class="iconfont">&#xe8c5;</i> {{ article.updateTime }}
                  </li>
                  </span>


                </ul>

              </div>
            </div>
          </el-card>
        </li>

      </ul>
      <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-size="5"
          layout="total, prev, pager, next, jumper"
          :total="articleTotal">
      </el-pagination>
    </div>

    <div class="right">
      <el-card class="box-card" v-if="!loginTag">
        <span><h4>登录网站，开启你的创作之旅：</h4></span>
        <div style="display: inline-block;margin-top: 20px"><i class="el-icon-time"></i>更懂你的优质内容</div>
        <div style="display: inline-block;margin-left: 15px"><i class="el-icon-time"></i>更专业的大咖答主</div>
        <div style="display: inline-block;margin-top: 20px"><i class="el-icon-time"></i>更深度的互动交流</div>
        <div style="display: inline-block;margin-left: 15px"><i class="el-icon-time"></i>更高效的创作环境</div>
        <el-button style="width: 100%;margin-top: 20px" type="primary" @click="openLogin">登录</el-button>
      </el-card>
      <el-calendar style="width: 350px;float: right;margin-top: 20px;background-color: #F0F2F5" v-model="value">
      </el-calendar>
    </div>

  </div>
</template>

<script>
import {newestListPage, hottestListPage} from "@/api/myArticle";
import {getMyHead} from "@/api/userInformation";
import bus from "@/store/bus";

export default {
  name: "ArticleList",
  data() {
    return {
      value: new Date(),
      circleUrl: "https://pic3.zhimg.com/v2-58d652598269710fa67ec8d1c88d8f03_r.jpg?source=1940ef5c",
      currentPage4: 1,
      articleTotal: 0,
      articleList: [],
      defaultTag: 'new',
      loginTag: false
    }
  },
  methods: {
    handleCurrentChange(val) {
      // console.log(val)
      if(this.defaultTag === 'new') {
        newestListPage(val,5).then(
            res => {
              // console.log(res.data)
              this.articleTotal = res.data.total
              this.articleList = res.data.articles
              this.currentPage4 = val
            }
        )
      } else {
        hottestListPage(val,5).then(
            res => {
              // console.log(res.data)
              this.articleTotal = res.data.total
              this.articleList = res.data.articles
              this.currentPage4 = val
            }
        )
      }
    },
    newest() {
      this.defaultTag = 'new'
      newestListPage(1,5).then(
          res => {
            // console.log(res.data)
            this.articleTotal = res.data.total
            this.articleList = res.data.articles
          }
      )
    },
    hottest() {
      this.defaultTag = 'hot'
      hottestListPage(1,5).then(
          res => {
            // console.log(res.data)
            this.articleTotal = res.data.total
            this.articleList = res.data.articles
          }
      )
    },
    xiangqing(id) {
      // console.log(id)
      this.$router.push({
        name: 'detailpage',
        params: {
          id:id
        }
      })
    },
    openLogin() {
      bus.$emit('openLogin',true)
    },
  },
  created() {
    // console.log(this.defaultTag)
    // console.log(this.currentPage4)
    if(this.defaultTag === 'new') {
      newestListPage(this.currentPage4,5).then(
          res => {
            // console.log(res.data)
            this.articleTotal = res.data.total
            this.articleList = res.data.articles
          }
      )
    } else {
      hottestListPage(this.currentPage4,5).then(
          res => {
            // console.log(res.data)
            this.articleTotal = res.data.total
            this.articleList = res.data.articles
          }
      )
    }
    // bus.$on('loginTag',val => {
    //   if(val) {
    //     this.loginTag = true
    //   }
    // })
    getMyHead().then(
        res => {
          if(res.data != null) {
            this.loginTag = true
          }
        }
    )

  }
}
</script>

<style scoped>

.al {
  padding: 10px;
  overflow: hidden;
  width: 100%;
  /*height: 400px;*/
  background-color: #FFFFFF;
}
.typeArea {
  width: 1380px;
  margin: 10px auto 0;
}
.first li {
  padding: 0 24px 5px 0;
  display: inline-block;
  list-style: none;
}
a {
  text-decoration: none;
  color: #000000;
}
.fgx {
  margin-bottom: 10px;
}
.card {
  width: 1000px;
  margin-bottom: 10px;
}
.card:hover {
  background-color: #F0F2F5;
}
.second {
  display: inline-block;
  width: 1000px;
}
.second li {
  list-style: none;
}
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  /*display: inline-block;*/
  float: right;
  width: 350px;
}
.list {
  float: left;
  display: inline-block;
  width: 1000px;
}
.right {
  float: right;
  width: 350px;
}
.right /deep/  .el-calendar-table .el-calendar-day{
  height: 45px;
}
.content {
  margin-top: 10px;
}
p {
  /*text-indent: 2em;*/
  line-height: 1.5;
}
.three li {
  padding-right: 10px;
  display: inline-block;
  list-style: none;
}
.hand:hover {
  cursor: pointer;
}
</style>
