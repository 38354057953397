<template>
  <div class="ac">
    <h3 style="font-size: 18px">评论</h3>
    <div style="margin-top: 20px" class="demo-basic--circle">
      <div class="block">
        <el-avatar :size="45" :src="circleUrl"></el-avatar>
      </div>
      <el-input
          style="width: 700px;margin-left: 20px;vertical-align: middle"
          type="textarea"
          autosize
          placeholder="输入评论"
          v-model="textarea1">
      </el-input>
      <el-button style="margin-left: 20px" size="small" type="primary" @click="comment">发表评论</el-button>
    </div>

    <h3 style="color: #252933;font-size: 18px;font-weight: 600;margin-top: 30px">全部评论 {{ commentNumber }}</h3>

    <div class="show" style="margin-top: 20px">
      <div class="commentList" v-for="comment in commentList" :key="comment.id">
        <div class="left" style="display: inline-block;width: 40px;overflow: hidden;vertical-align: top">
          <el-avatar :size="40" :src="comment.userAvatar"></el-avatar>
        </div>
        <div class="right" style="display: inline-block;width: 915px;overflow: hidden;margin-left: 15px">
          <div class="top" style="overflow: hidden">
            <span style="float: left">{{ comment.nickName }}</span>
            <span style="float: right;color: #8a919f;font-size: 14px">{{ comment.createTime }}</span>
          </div>
          <div class="content" style="padding: 15px 0 5px 0">
            {{ comment.content }}
          </div>
          <div class="bottom">
            <i style="color: #8a919f" class="el-icon-chat-dot-square"></i>
<!--            <span style="color: #8a919f;font-size: 14px"> 回复</span>-->

            <div style="display: inline-block" v-if="comment.id === currentId"><el-button class="aaa" style="color: #8a919f" type="text" @click="currentId = ''">取消回复</el-button></div>
            <div style="display: inline-block" v-else><el-button class="aaa" style="color: #8a919f" type="text" @click="currentId = comment.id">回复</el-button></div>
          </div>
          <div v-if="comment.id === currentId" style="margin-bottom: 20px">
            <el-input
                style="width: 700px;"
                type="textarea"
                autosize
                placeholder="输入评论"
                v-model="textarea">
            </el-input>
            <el-button style="margin-left: 20px" size="small" type="primary" @click="erjicomment(comment.id)">发表评论</el-button>
          </div>
          <div class="child" style="background-color: rgba(247,248,250,.7);padding: 10px" v-if="comment.child != null">
            <div v-for="child in comment.child" :key="child.id">
            <div style="display: inline-block;width: 24px;overflow: hidden;vertical-align: top">
              <el-avatar :size="24" :src="child.userAvatar"></el-avatar>
            </div>
            <div style="display: inline-block;width: 855px;overflow: hidden;margin-left: 15px;">
              <div style="overflow: hidden">
                <span style="float: left">{{ child.nickName }}</span>
                <span style="float: left;margin-left: 10px;font-size: 14px;color: #8a919f">回复</span>
                <span style="float: left;margin-left: 10px">{{ child.parentNickName }}</span>
                <span style="float: right;color: #8a919f;font-size: 14px">{{ child.createTime }}</span>
              </div>
              <div style="padding: 15px 0 5px 0">
                {{ child.content }}
              </div>
              <div>
                <i style="color: #8a919f" class="el-icon-chat-dot-square"></i>
                <!--            <span style="color: #8a919f;font-size: 14px"> 回复</span>-->
                <div style="display: inline-block" v-if="currentChildId === child.id"><el-button class="aaa" style="color: #8a919f" type="text" @click="currentChildId = ''">取消回复</el-button></div>
                <div style="display: inline-block" v-else><el-button class="aaa" style="color: #8a919f" type="text" @click="currentChildId = child.id">回复</el-button></div>
              </div>
              <div v-if="currentChildId === child.id">
                <el-input
                    style="width: 700px;"
                    type="textarea"
                    autosize
                    placeholder="输入评论"
                    v-model="textarea2">
                </el-input>
                <el-button style="margin-left: 20px" size="small" type="primary" @click="sanjireply(comment.id, child.id)">发表评论</el-button>
              </div>
            </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {writeComment} from "@/api/myArticle";
import {listComments, getCommentNumber} from "@/api/myArticle";
import {getMyHead} from "@/api/userInformation";

export default {
  name: "ArticleComment",
  components: {},
  data() {
    return {
      textarea: '',
      textarea1: '',
      textarea2: '',
      circleUrl: require('../../../assets/login.png'),
      flag: true,
      replayFlag: true,
      commentList: [],
      currentId: '',
      currentChildId: '',
      commentNumber: '',
    }
  },
  methods: {
    erjicomment(id) {
      if(this.textarea === '' || this.textarea === null) {
        this.$notify({
          title: '警告',
          message: '评论内容不能为空',
          type: 'warning'
        });
        return;
      }
      const articleId = this.$route.params.id
      const articleComment = {
        content: this.textarea,
        parentId: id,
        rootParentId: id,
        articleId: articleId
      }
      writeComment(articleComment).then(
          res => {
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
            this.getComments(articleId)
            this.commentNum(articleId)
            this.textarea = ''
          }
      )
    },
    sanjireply(rootId,parentId) {
      if(this.textarea2 === '' || this.textarea2 === null) {
        this.$notify({
          title: '警告',
          message: '评论内容不能为空',
          type: 'warning'
        });
        return;
      }
      // console.log(rootId,parentId)
      const articleId = this.$route.params.id
      const articleComment = {
        content: this.textarea2,
        parentId: parentId,
        rootParentId: rootId,
        articleId: articleId
      }
      writeComment(articleComment).then(
          res => {
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
            this.getComments(articleId)
            this.commentNum(articleId)
            this.textarea2 = ''
          }
      )
    },
    comment() {
      if(this.textarea1 === '' || this.textarea1 === null) {
        this.$notify({
          title: '警告',
          message: '评论内容不能为空',
          type: 'warning'
        });
        return;
      }
      const id = this.$route.params.id
      const articleComment = {
        content: this.textarea1,
        articleId: id
      }
      writeComment(articleComment).then(
          res => {
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
            this.getComments(id)
            this.commentNum(id)
            this.textarea1 = ''
          }
      )
    },
    getComments(id) {
      listComments(id).then(
          res => {
            // console.log(res.data)
            this.commentList = res.data
          }
      )
    },
    commentNum(id) {
      getCommentNumber(id).then(
          res => {
            this.commentNumber = res.data
          }
      )
    }
  },
  created() {
    const articleId = this.$route.params.id
    this.getComments(articleId)
    this.commentNum(articleId)
    getMyHead().then(
        res => {
          this.circleUrl = res.data.head
        }
    )
  }
}
</script>

<style scoped>
.ac {
  margin-top: 10px;
  border-radius: 4px;
  padding: 20px;
  background-color: white;
}
.block {
  display: inline-block;
  vertical-align: middle;
}
</style>
