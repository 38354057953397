import request from "@/utils/request";

//获取用户信息
export function getUserInformation() {
    return request({
        method: 'get',
        url: '/juanbaUser/api/v1/getUserInformation',
    })
}

//登录
export function login(juanbaUserDto) {
    return request({
        method: 'post',
        url: '/juanbaUser/api/v1/login',
        data: juanbaUserDto
    })
}

//注册
export function register(juanbaUserDto) {
    return request({
        method: 'post',
        url: '/juanbaUser/api/v1/register',
        data: juanbaUserDto
    })
}