<template>
  <div class="nbwai">
    <div class="nb">
      <div class="header_left">
        <router-link active-class="active" to="/home">
          <img class="logo" src="../../assets/logo_5.png">
          <h1>卷吧</h1>
        </router-link>
      </div>
      <ul>
<!--        <a href="#">-->
        <router-link active-class="active" to="/home">
          <li>首页</li>
        </router-link>
<!--        </a>-->
        <router-link active-class="active" to="/question">
          <li>问答广场</li>
        </router-link>
        <router-link active-class="active" to="/todoList">
          <li>任务清单</li>
        </router-link>
        <router-link active-class="active" to="/commonUtils">
          <li>常用工具类</li>
        </router-link>
<!--        <a href="#">-->
<!--          <li>找队友</li>-->
<!--        </a>-->
<!--        <a href="#">-->
<!--          <li>任务悬赏</li>-->
<!--        </a>-->
<!--        <a href="#">-->
<!--          <li>面试</li>-->
<!--        </a>-->
<!--        <a href="#">-->
        <router-link active-class="active" :to="{name: 'personaldata'}">
          <li>个人中心</li>
        </router-link>

<!--        </a>-->
      </ul>
      <div class="search">
        <el-input size="small" placeholder="请输入内容" v-model="input3" class="input-with-select">
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <el-button style="margin-left: 20px" size="small" type="primary" icon="el-icon-edit" @click="write()">创作</el-button>
      <el-button style="margin-left: 20px" type="warning" icon="el-icon-bell" circle></el-button>

      <div class="demo-basic--circle avatar">

        <el-popover
            style="text-align: center"
            placement="bottom"
            width="150"
            trigger="hover">
          <div v-if="loginTag">
            <div class="hand" style="text-align: center;padding: 5px" @click="toPersonal">个人中心</div>
            <div class="hand" style="text-align: center;padding: 5px" @click="logout">退出登录</div>
          </div>
          <div v-else>
            <div class="hand" style="text-align: center;padding: 5px" @click="openLogin">登录</div>
          </div>
          <div class="block" slot="reference"><el-avatar :size="50" :src="circleUrl"></el-avatar></div>
        </el-popover>

<!--        <el-tooltip content="Bottom center" placement="bottom" effect="light">-->
<!--          <div class="block"><router-link :to="{name:'personaldata'}"><el-avatar :size="50" :src="circleUrl"></el-avatar></router-link></div>-->
<!--        </el-tooltip>-->

      </div>
    </div>
  </div>
</template>

<script>
import {getMyHead} from "@/api/userInformation";
import bus from "@/store/bus";
import {removeToken} from "@/utils/tokenUtil";

export default {
  name: "NavigationBar",
  data () {
    return {
      circleUrl: require('../../assets/login.png'),
      loginTag: false
    }
  },
  methods: {
    toPersonal() {
      this.$router.push({
        name: 'personaldata'
      })
    },
    write() {
      this.$router.push({
        name: 'publisharticle'
      })
    },
    openLogin() {
      bus.$emit('openLogin',true)
    },
    logout() {
      this.$confirm('是否确定退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '退出登录成功!'
        });
        //移除本地token
        removeToken()
        //刷新页面
        this.$router.go(0)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消退出登录'
        });
      });
    }
  },
  created() {
    getMyHead().then(
        res => {
          this.circleUrl = res.data.head
          this.loginTag = true
          bus.$emit('loginTag',true)
        }
    )
  }
}
</script>

<style scoped>
.nbwai {
  width: 100%;
  height: 60px;
  background-color: #FFFFFF;

}

.nb {
  position: fixed;
  z-index: 999;
  padding-left: 16px;
  line-height: 60px;
  width: 100%;
  height: 60px;
  background-color: #FFFFFF;
}

.header_left {
  display: inline-block;
  float: left;
  margin-left: 8px;
  line-height: 60px;
  width: 110px;
  height: 60px;
  background-color: #FFFFFF;
}

.logo {
  display: inline-block;
  vertical-align: middle;
  height: 40px;
}

h1 {
  display: inline-block;
  float: right;
  background-color: #FFFFFF;
  font-weight: 400;
  font-size: 25px;
  font-family: cursive;
  color: #000000;
}

ul {
  display: inline-block;
  margin-left: 60px;
  float: left;
  width: 595px;
  list-style-type: none;
  background-color: #FFFFFF;
}

li {
  padding: 0 10px;
  display: inline-block;
  background-color: #FFFFFF;
}

a {
  text-decoration: none;
  color: #000000;
}

a:hover {
  color: #1890FF;
}

.search {
  background-color: #FFFFFF;
  display: inline-block;
  margin-left: 40px;
  width: 400px;
}
.avatar {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
}
.block {
  margin-top: 5px;
  margin-left: 20px;
  line-height: 60px;
  height: 60px;
}
.active{
  color: #1890FF;
}
.hand:hover {
  cursor: pointer;
  background-color: #F7F8FA;
  color: #1E80FF;
}
</style>
