<template>
  <div class="da">
    <h1 style="font-size: 28px">{{ title }}</h1>
    <div class="data">
      <div class="top">
        <span style="color: #555666;font-size: 14px">{{ author }}</span>
        <span style="margin-left: 20px;color: #999aaa;font-size: 14px">
          <i class="iconfont">&#xe8c5;</i>
          于 {{ createTime }} 发布
        </span>
        <span style="margin-left: 20px;color: #999aaa;font-size: 14px">
          <i class="iconfont">&#xe69a;</i>
          {{ lookNumber }}
        </span>
        <span style="margin-left: 20px;color: #999aaa;font-size: 14px">
          <i class="iconfont">&#xec8c;</i>
          点赞 {{ likeNumber }}
        </span>
        <span style="margin-left: 20px;color: #999aaa;font-size: 14px">
          <i class="iconfont">&#xe636;</i>
          收藏 {{ collectNumber }}
        </span>
        <div class="littletag">
          <div style="color: white">
            <span v-if="articleType === 'ORIGINAL'">原创</span>
            <span v-if="articleType === 'REPRINT'">转载</span>
            <span v-if="articleType === 'TRANSLATE'">翻译</span>
          </div>
        </div>
      </div>
      <div class="bottom">
        <span style="color: #999aaa;font-size: 14px">文章标签：</span>
        <ul>
          <li v-for="tag in articleTags" :key="tag.id"><el-tag style="margin-right: 10px" size="small">{{ tag.name }}</el-tag></li>
        </ul>
      </div>
    </div>
    <div class="article">
      <Editor
          style="min-height: 10px; overflow-y: hidden;"
          v-model="content"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="onCreated"
      />
    </div>
  </div>
</template>

<script>
import { Editor } from '@wangeditor/editor-for-vue'
import {getArtcleById, getTagByArticleId, articleLikeCount, articleCollectNumber, getArticleLookNum} from "@/api/myArticle";
import {getNickNameById} from "@/api/userInformation";
import bus from "@/store/bus";

export default {
  name: "DetailArticle",
  components: { Editor },
  data() {
    return {
      editor: null,
      html: '',
      toolbarConfig: { },
      editorConfig: {
        placeholder: '',
        scroll: false,
        readOnly: true
      },
      mode: 'default', // or 'simple'
      title: '',  //文章标题
      author: '', //作者
      createTime: '', //发布时间
      articleType: '', //文章类型
      content: '',  //文章内容
      articleTags: '', //文章标签
      likeNumber: '', //文章点赞数
      collectNumber: '',  //文章收藏数
      lookNumber: '', //文章浏览量
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    getArticleLikeCount(id) {
      articleLikeCount(id).then(
          res => {
            this.likeNumber = res.data
          }
      )
    },
    getArticleCollectCount(id) {
      articleCollectNumber(id).then(
          res => {
            this.collectNumber = res.data
          }
      )
    },
    getArticleLookCount(id) {
      getArticleLookNum(id).then(
          res => {
            this.lookNumber = res.data
          }
      )
    }
  },
  created() {
    const articleId = this.$route.params.id
    bus.$on('flushLike',res => {
      if(res)
        this.getArticleLikeCount(articleId)
    })
    bus.$on('flushCollect',res => {
      if(res)
        this.getArticleCollectCount(articleId)
    })
    getArtcleById(articleId).then(
        res => {
          // console.log(res.data)
          const article = res.data
          this.title = article.title
          this.createTime = article.createTime
          this.articleType = article.articleType
          this.content = article.content
          getNickNameById(article.userId).then(
              res => {
                this.author = res.data
              }
          )
        }
    )
    getTagByArticleId(articleId).then(
        res => {
          this.articleTags = res.data
        }
    )
    this.getArticleLikeCount(articleId)
    this.getArticleCollectCount(articleId)
    this.getArticleLookCount(articleId)
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
    // }, 1500)
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
}
</script>

<style scoped>
.da {
  padding: 10px 24px 16px;
  border-radius: 4px;
  background-color: white;
}
.data {
  width: 100%;
  /*height: 61px;*/
  background-color: #F8F8F8;
  border-radius: 4px;
  margin-top: 10px;
  padding: 0 10px 0 0;
}
.top {
  padding: 5px 10px 10px 20px;
}
.bottom {
  padding: 0 10px 5px 20px;
}
.littletag {
  width: 50px;
  height: 40px;
  line-height: 40px;
  background-color: #42A7FF;
  float: right;
  border-radius: 20px 5px 5px 20px;
  margin-top: 9px;
  padding: 0 0 0 12px
}
ul {
  display: inline-block;
}
li {
  list-style: none;
  display: inline-block;
}
</style>
