<template>
  <div class="pa">
      <ArticleEditor></ArticleEditor>
    <h3 style="margin-top: 20px;font-weight: 400;margin-left: 10px">发文设置</h3>
    <PublishSetting></PublishSetting>
  </div>
</template>

<script>
import ArticleEditor from "@/components/personalcenter/typearea/show/commonutils/ArticleEditor";
import PublishSetting from "@/components/framercenter/publishmanage/PublishSetting";
export default {
  name: "PublishArticle",
  components: {PublishSetting, ArticleEditor},
  methods: {
  },
  mounted() {
    // window.onbeforeunload = function(e) {
    //   e = e || window.event;
    //   // 兼容IE8和Firefox 4之前的版本
    //   if (e) {
    //     e.returnValue = "您是否确认离开此页面-您输入的数据可能不会被保存";
    //   }
    //   // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
    //   return "您是否确认离开此页面-您输入的数据可能不会被保存";
    // };
  },
}

</script>

<style scoped>

</style>
