import request from "@/utils/request";

export function addUtil(util) {
    return request({
        method: 'post',
        url: '/myUtils/api/v1/addUtil',
        data: util
    })
}

export function getUtilById(id) {
    return request({
        method: 'get',
        url: '/myUtils/api/v1/getUtilsById/'+id
    })
}

export function getUtils(utilMessage) {
    return request({
        method: 'get',
        params: {
            utilMessage
        },
        url: '/myUtils/api/v1/getUtils'
    })
}

export function delUtil1(id) {
    return request({
        method: 'delete',
        url: '/myUtils/api/v1/delUtil/'+id
    })
}

export function updateUtil(util) {
    return request({
        method: 'put',
        url: '/myUtils/api/v1/updateUtil',
        data: util
    })
}