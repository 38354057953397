<template>
  <div class="dq">

    <div class="bg">
      <div>
        <div @click="zan" class="hand" style="width: 50px;height: 50px;border-radius: 50%;background-color: #fff;text-align: center;line-height: 50px">
          <i v-if="isOrNoLike === true" class="iconfont" style="color: #1E80FF">&#xec8c;</i>
          <i v-else class="iconfont">&#xec8c;</i>
        </div>
      </div>
      <div style="margin-top: 20px">
        <div class="hand" style="width: 50px;height: 50px;border-radius: 50%;background-color: #fff;text-align: center;line-height: 50px">
          <i class="iconfont">&#xe624;</i>
        </div>
      </div>
      <div style="margin-top: 20px">
        <div @click="collect" class="hand" style="width: 50px;height: 50px;border-radius: 50%;background-color: #fff;text-align: center;line-height: 50px">
          <i v-if="isOrNoCollect === true" class="iconfont" style="color: #1E80FF">&#xe636;</i>
          <i v-else class="iconfont">&#xe636;</i>
        </div>
      </div>
      <div class="backtop" style="height: 800px;overflow-y: auto">
        <el-backtop target=".bg" :visibility-height=400></el-backtop>
      </div>

    </div>

    <div class="ct">
      <div class="detailInformation">
        <div class="head">
          <div style="display: inline-block;vertical-align: top" class="block"><el-avatar :size="40" :src="circleUrl"></el-avatar></div>
          <div style="display: inline-block;margin-left: 20px">
            <div style="font-size: 16px;color: rgba(0,0,0,.85)">{{ userNickName }}</div>
            <div style="margin-top: 10px;font-size: 14px;color: rgba(0,0,0,.45)">
              <span>{{ createTime }}</span>
              <span style="margin-left: 20px">阅读 {{ questionLookNum }}</span>
            </div>
          </div>
          <div v-if="!attentionTag" style="display: inline-block;float:right;margin-right: 80px"><el-button size="small" type="primary" icon="el-icon-plus" @click="attention">关注</el-button></div>
          <div v-else style="display: inline-block;float:right;margin-right: 80px"><el-button size="small" type="primary" @click="attention">取消关注</el-button></div>
        </div>
        <el-divider></el-divider>
        <div class="body">
          <h1 style="font-size: 28px">{{ questionTitle }}</h1>
          <div class="content" style="margin-top: 10px">
            <Editor
                style="min-height: 10px; overflow-y: hidden;"
                v-model="content"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
            />
          </div>
        </div>
      </div>
      <div class="comment">
        <div style="display: inline-block;vertical-align: top" class="block"><el-avatar :size="40" :src="circleUrl1"></el-avatar></div>
        <div style="display: inline-block">
          <CommentEditor ref="ref1" style="width: 1000px;margin-left: 20px"></CommentEditor>
          <el-button style="float: right;margin-top: 10px" size="small" type="primary" @click="comment">发表评论</el-button>
        </div>
        <el-divider></el-divider>
        <div class="commentList">
          <h3 style="font-size: 18px">解答</h3>

          <div class="show" style="margin-top: 20px">
            <div class="commentList" v-for="comment in commentList" :key="comment.id">
              <div class="left" style="display: inline-block;width: 40px;overflow: hidden;vertical-align: top">
                <el-avatar :size="40" :src="comment.userAvatar"></el-avatar>
              </div>
              <div class="right" style="display: inline-block;width: 915px;overflow: hidden;margin-left: 15px">
                <div class="top" style="overflow: hidden">
                  <span style="float: left">{{ comment.nickName }}</span>
                  <span style="float: right;color: #8a919f;font-size: 14px">{{ comment.createTime }}</span>
                </div>
                <div class="content" >
<!--                  {{ comment.content }}-->
                  <Editor
                      style="min-height: 10px; overflow-y: hidden;"
                      v-model="comment.content"
                      :defaultConfig="editorConfig"
                      :mode="mode"
                      @onCreated="onCreated"
                  />
                </div>
                <div class="bottom">
                  <i style="color: #8a919f" class="el-icon-chat-dot-square"></i>
                  <!--            <span style="color: #8a919f;font-size: 14px"> 回复</span>-->

                  <div style="display: inline-block" v-if="comment.id === currentId"><el-button class="aaa" style="color: #8a919f" type="text" @click="currentId = ''">取消回复</el-button></div>
                  <div style="display: inline-block" v-else><el-button class="aaa" style="color: #8a919f" type="text" @click="currentId = comment.id">回复</el-button></div>
                </div>
                <div v-if="comment.id === currentId" style="margin-bottom: 20px">
<!--                  <el-input-->
<!--                      style="width: 700px;"-->
<!--                      type="textarea"-->
<!--                      autosize-->
<!--                      placeholder="输入评论"-->
<!--                      v-model="textarea">-->
<!--                  </el-input>-->
                  <CommentEditor ref="ref2" style="width: 915px;"></CommentEditor>
                  <el-button style="margin-bottom: 5px;margin-top: 10px" size="small" type="primary" @click="erjicomment(comment.id)">发表评论</el-button>
                </div>
                <div class="child" style="background-color: rgba(247,248,250,.7);padding: 10px" v-if="comment.child != null">
                  <div v-for="child in comment.child" :key="child.id">
                    <div style="display: inline-block;width: 24px;overflow: hidden;vertical-align: top">
                      <el-avatar :size="24" :src="child.userAvatar"></el-avatar>
                    </div>
                    <div style="display: inline-block;width: 855px;overflow: hidden;margin-left: 15px;">
                      <div style="overflow: hidden">
                        <span style="float: left">{{ child.nickName }}</span>
                        <span style="float: left;margin-left: 10px;font-size: 14px;color: #8a919f">回复</span>
                        <span style="float: left;margin-left: 10px">{{ child.parentNickName }}</span>
                        <span style="float: right;color: #8a919f;font-size: 14px">{{ child.createTime }}</span>
                      </div>
                      <div style="padding: 15px 0 5px 0;">
<!--                        {{ child.content }}-->
                        <Editor
                            style="min-height: 10px; overflow-y: hidden;"
                            v-model="child.content"
                            :defaultConfig="editorConfig"
                            :mode="mode"
                            @onCreated="onCreated"
                        />
                      </div>
                      <div>
                        <i style="color: #8a919f" class="el-icon-chat-dot-square"></i>
                        <!--            <span style="color: #8a919f;font-size: 14px"> 回复</span>-->
                        <div style="display: inline-block" v-if="currentChildId === child.id"><el-button class="aaa" style="color: #8a919f" type="text" @click="currentChildId = ''">取消回复</el-button></div>
                        <div style="display: inline-block" v-else><el-button class="aaa" style="color: #8a919f" type="text" @click="currentChildId = child.id">回复</el-button></div>
                      </div>
                      <div v-if="currentChildId === child.id">
<!--                        <el-input-->
<!--                            style="width: 700px;"-->
<!--                            type="textarea"-->
<!--                            autosize-->
<!--                            placeholder="输入评论"-->
<!--                            v-model="textarea2">-->
<!--                        </el-input>-->
                        <CommentEditor ref="ref3" style="width: 855px;"></CommentEditor>
                        <el-button style="margin-bottom: 5px;margin-top: 10px" size="small" type="primary" @click="sanjireply(comment.id, child.id)">发表评论</el-button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Editor } from '@wangeditor/editor-for-vue'
import {getQuestionById, isCollect, isLike, setQuestionLike, collectQuestion} from "@/api/question";
import {attentionUser, isAttention} from "@/api/attention";
import CommentEditor from "@/components/personalcenter/typearea/show/commonutils/CommentEditor.vue";
import {writeComment, listComments} from "@/api/question";
import {getMyHead} from "@/api/userInformation";

export default {
  name: "DetailQuestion",
  components: {CommentEditor, Editor },
  data() {
    return {
      editorConfig: {
        placeholder: '',
        scroll: false,
        readOnly: true
      },
      mode: 'default', // or 'simple'
      circleUrl: require('../../assets/login.png'),
      circleUrl1: require('../../assets/login.png'),
      beFollowerId: '',
      userNickName: '',
      questionLookNum: '',
      createTime: '',
      questionTitle: '',
      content: 'juanba如何设计与实现？',
      isOrNoLike: false,  //是否点赞
      isOrNoCollect: false, //是否收藏
      attentionTag: false,  //是否关注
      commentList: [],  //评论列表
      currentId: '',
      currentChildId: '',

    }
  },
  methods: {
    comment() {
      //获取评论框内容
      const content = this.$refs.ref1.editor.getConfig.value
      if(content === '<p><br></p>' || content === '') {
        this.$notify({
          title: '警告',
          message: '评论内容不能为空',
          type: 'warning'
        });
        return;
      }
      //获取问题id
      const id = this.$route.params.id
      const questionComment = {
        content: content,
        questionId: id
      }
      writeComment(questionComment).then(
          res => {
              this.$notify({
                title: '成功',
                message: res.data,
                type: 'success'
              });
            this.$refs.ref1.editor.setHtml('')
            this.getComments(id)
          }
      )
    },
    erjicomment(id) {
      //获取评论框内容
      // console.log(this.$refs.ref2[0].editor.getConfig.value)
      const content = this.$refs.ref2[0].editor.getConfig.value
      if(content === '<p><br></p>' || content === '') {
        this.$notify({
          title: '警告',
          message: '评论内容不能为空',
          type: 'warning'
        });
        return;
      }
      const questionId = this.$route.params.id
      const questionComment = {
        content: content,
        parentId: id,
        rootParentId: id,
        questionId: questionId
      }
      writeComment(questionComment).then(
          res => {
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
            this.getComments(questionId)
            this.$refs.ref2[0].editor.setHtml('')
          }
      )
    },
    sanjireply(rootId,parentId) {
      // console.log(rootId,parentId)
      const content = this.$refs.ref3[0].editor.getConfig.value
      if(content === '<p><br></p>' || content === '') {
        this.$notify({
          title: '警告',
          message: '评论内容不能为空',
          type: 'warning'
        });
        return;
      }
      const questionId = this.$route.params.id
      const questionComment = {
        content: content,
        parentId: parentId,
        rootParentId: rootId,
        questionId: questionId
      }
      writeComment(questionComment).then(
          res => {
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
            this.getComments(questionId)
            this.$refs.ref3[0].editor.setHtml('')
          }
      )
    },
    getComments(id) {
      listComments(id).then(
          res => {
            // console.log(res.data)
            this.commentList = res.data
          }
      )
    },
    //赞/取消赞
    zan() {
      const questionId = this.$route.params.id
      setQuestionLike(questionId).then(
          res => {
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
            this.isLikeQuestion(questionId)
          }
      )
    },
    //收藏/取消收藏
    collect() {
      const questionId = this.$route.params.id
      collectQuestion(questionId).then(
          res => {
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
            this.isCollectQuestion(questionId)
          }
      )
    },
    //判断问题是否被点赞
    isLikeQuestion(id) {
      isLike(id).then(
          res => {
            // console.log(res)
            this.isOrNoLike = res.data
          }
      )
    },
    //判断问题是否被收藏
    isCollectQuestion(id) {
      isCollect(id).then(
          res => {
            this.isOrNoCollect = res.data
          }
      )
    },
    attention() {
      attentionUser(this.beFollowerId).then(
          res => {
            // console.log(res)
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
            this.ifAttention()
          }
      )
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    ifAttention() {
      isAttention(this.beFollowerId).then(
          res => {
            // console.log(res)
            this.attentionTag = res.data
          }
      )
    }
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  created() {
    const questionId = this.$route.params.id
    getQuestionById(questionId).then(
        res => {
          // console.log(res)
          this.circleUrl = res.data.userHead
          this.userNickName = res.data.userName
          this.beFollowerId = res.data.userId
          this.questionTitle = res.data.title
          this.content = res.data.content
          this.createTime = res.data.createTime
          this.questionLookNum = res.data.lookNumber
          this.ifAttention()
          this.isLikeQuestion(questionId)
          this.isCollectQuestion(questionId)
        }
    )
    this.getComments(questionId)
    getMyHead().then(
        res => {
          this.circleUrl1 = res.data.head
        }
    )
  }
}
</script>

<style scoped>
.dq {
  padding: 10px;
  background-color: #F0F2F5;
  width: 100%;
}
.detailInformation {
  width: 100%;
  background-color: #FFFFFF;
  padding: 20px;
}
.comment {
  width: 100%;
  background-color: #FFFFFF;
  padding: 20px;
  margin-top: 10px;
}
.ct {
  width: 1200px;
  margin: 0 auto;
  padding: 10px;
}
.bg {
  position: fixed;
  margin-top: 100px;
  margin-left: 50px;
}
.hand:hover {
  cursor:pointer;
}
</style>