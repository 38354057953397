import Vue from 'vue'
import App from './App.vue'

import "./assets/iconfont/iconfont.css"

import store from "@/store";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VueRouter from "vue-router";
Vue.use(VueRouter)

import router from "@/router";

import { Boot } from '@wangeditor/editor'
import markdownModule from '@wangeditor/plugin-md'

Boot.registerModule(markdownModule)


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
