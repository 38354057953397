import axios from "axios";
import { getToken } from "@/utils/tokenUtil";
import bus from "@/store/bus";

const request = axios.create({
    baseURL: 'http://39.103.60.183:8081',
    // baseURL: 'http://139.199.4.152:8081',
    timeout: 20000
})

//请求拦截器
request.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    config.headers['authorization'] = getToken('token')
    return config;
}, function (error) {
    // 对请求错误做些什么
    console.log('请求失败',error)
    return Promise.reject(error);
});

//响应拦截器
request.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    const res = response.data
    // console.log('res',res)
    if(res.code === 1) {
        bus.$emit('openLogin',true)
    }
    return res
}, function (error) {
    // console.log('error',error.response.status)
    if(error.response.status === 401) {
        bus.$emit('openLogin',true)
    }
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
});



export default request
