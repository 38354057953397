import VueRouter from "vue-router";

import HomePage from "@/view/HomePage";
import PersonalCenter from "@/view/PersonalCenter";
import PersonalData from "@/components/personalcenter/typearea/show/PersonalData";
import TodoList from "@/view/TodoList.vue"
import BugRecord from "@/components/personalcenter/typearea/show/BugRecord";
import ArticleManage from "@/components/framercenter/articlemanage/ArticleManage";
import FramerCenter from "@/components/framercenter/FramerCenter";
import PublishArticle from "@/components/framercenter/publishmanage/PublishArticle";
import CommontManage from "@/components/framercenter/commontmanage/CommontManage";
import DetailPage from "@/components/detailpage/DetailPage";
import DetailUtil from "@/components/personalcenter/typearea/show/commonutils/DetailUtil.vue";
import PublishSetting from "@/components/framercenter/publishmanage/PublishSetting.vue";
import QuestionAnswer from "@/view/QuestionAnswer.vue";
import DetailQuestion from "@/components/detailquestion/DetailQuestion.vue";
import CommonUtils from "@/view/CommonUtils.vue";
import MyCollect from "@/components/personalcenter/typearea/show/MyCollect.vue";
import MyFuns from "@/components/personalcenter/typearea/show/MyFuns.vue";

const router = new VueRouter({
    routes:[
        {
            path:'/',
            component:HomePage
        },
        {
            path:'/home',
            component:HomePage
        },
        {
            path: '/question',
            component:QuestionAnswer
        },
        {
            name:'detailpage',
            path:'/detailpage/:id',
            component:DetailPage
        },
        {
            name: 'detailQuestion',
            path:'/detailQuestion/:id',
            component:DetailQuestion
        },
        {
            name: 'todoList',
            path:'/todoList',
            component:TodoList
        },
        {
            name: 'commonUtils',
            path:'/commonUtils',
            component:CommonUtils
        },
        {
            path:'/framercenter',
            component:FramerCenter,
            children: [
                {
                    path: '',
                    component: ArticleManage
                },
                {
                    name: 'publisharticle',
                    path: 'publisharticle',
                    component: PublishArticle,
                    children: [
                        {
                            path: 'publishsetting',
                            name: 'publishsetting',
                            component: PublishSetting
                        }
                    ]
                },
                {
                    name: 'articlemanage',
                    path: 'articlemanage',
                    component: ArticleManage
                },
                {
                    name: 'commontmanage',
                    path: 'commontmanage',
                    component: CommontManage
                },
            ]
        },
        {
            path:'/articlemanage',
            component: ArticleManage
        },
        {
            name: 'detailUtil',
            path: 'detailUtil/:id',
            component: DetailUtil
        },
        {
            path:'/personalcenter',
            component:PersonalCenter,
            children: [
                {
                    path:'',
                    component:PersonalData
                },
                {
                    name:'personaldata',
                    path:'personaldata',
                    component:PersonalData
                },
                {
                    name:'myCollect',
                    path:'myCollect',
                    component:MyCollect
                },
                {
                    name:'myFuns',
                    path:'myFuns',
                    component:MyFuns
                },
                // {
                //     name:'todolist',
                //     path:'todolist',
                //     component:TodoList
                // },
                // {
                //     name:'commonutils',
                //     path:'commonutils',
                //     component:CommonUtils,
                // },
                {
                    name:'bugrecord',
                    path:'bugrecord',
                    component:BugRecord
                }
            ]
        }
    ]
})

export default router;
