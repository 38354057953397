import request from "@/utils/request";

export function addTask(task) {
    return request({
        method: 'post',
        url: 'myTasks/api/v1/addTask',
        data: task
    })
}

export function getTasks() {
    return request({
        method: 'get',
        url: 'myTasks/api/v1/getTasks',
    })
}

export function delTask(id) {
    return request({
        method: 'delete',
        url: 'myTasks/api/v1/delTask/'+id,
    })
}

export function updateStatus(id, status) {
    return request({
        method: 'put',
        url: 'myTasks/api/v1/updateTaskStatus/'+id,
        params: {
            status: !status
        }
    })
}

export function updateDeadline(id, deadline) {
    return request({
        method: 'put',
        url: 'myTasks/api/v1/updateDeadline/'+id,
        params: {
            deadline: deadline
        }
    })
}