<template>
  <div class="cu">
    <div class="top1" style="margin-bottom: 10px;text-align: center">
      <span style="font-size: 25px;color: rgba(0, 0, 0, 0.45)">
        收藏常用工具类，避免重复造轮子！
      </span>
    </div>
    <div class="center">
      <el-button size="medium" style="width: 100%;margin-bottom: 20px" type="primary"
                 @click="openEditor()"
      >添加一条工具类</el-button>
      <el-input
          size="medium "
          style="width: 1000px;margin-right: 10px"
          placeholder="请输入内容"
          v-model="searchParam">
      </el-input>
      <div style="display: inline-block;float: right;">
        <el-button size="medium" @click="chongzhi">重置</el-button>
        <el-button type="primary" size="medium" @click="getUtilsList(searchParam)">搜索</el-button>
      </div>

      <el-divider>工具类列表展示</el-divider>

      <div class="list">
        <ul class="utils">
          <li class="util" v-for="util in utilList" :key="util.id">
            <el-card class="card" shadow="never">
              <div class="box">
                <div class="left">
                  <h3 style="font-weight: 400">工具类作用描述：</h3>
                  <p @click="toDetailUtil(util.id,util.description,util.utilsCode)" style="margin-top: 10px">{{util.description}}</p>
                </div>
                <div class="right">
                  <div class="top" style="height: 45px;text-align: right">
                    {{util.createTime}}
                  </div>
                  <div class="bottom" style="height: 45px;text-align: right">
                    <ul style="margin-top: 25px">
                      <li @click="edit(util.description,util.utilsCode,util.id)">编辑</li>
                      <li @click="toDetailUtil(util.id,util.description,util.utilsCode)">查看</li>
                      <li style="padding-right: 0" @click="delUtil(util.id)">删除</li>
                    </ul>
                  </div>
                </div>
              </div>

            </el-card>
          </li>
        </ul>
      </div>

      <el-dialog style="border-radius: 5px" title="添加工具类" :visible.sync="dialogFormVisible">
        <el-form :model="form">
          <h3 style="font-weight: 500;margin-bottom: 10px">工具类作用：</h3>
          <!--        <DescriptionEditor style="z-index: 999"></DescriptionEditor>-->
          <el-input
              type="textarea"
              autosize
              placeholder="请描述工具类作用"
              v-model="textarea1">
          </el-input>
          <h3 style="font-weight: 500;margin-bottom: 10px;margin-top: 25px">工具类代码：</h3>
          <!--        <CodeEditor></CodeEditor>-->
          <div style="border: 1px solid #ccc;">
            <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
            />
            <Editor
                style="height: 300px; overflow-y: hidden;"
                v-model="html"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
                @onChange="onChange"
            />
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getForm()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import {addUtil, getUtils, delUtil1, updateUtil} from "@/api/myUtils";

export default {
  name: "CommonUtils",
  components: {Editor, Toolbar},
  data: function () {
    return {
      dialogFormVisible: false,
      formLabelWidth: '120px',
      form: {},
      utilList: [],
      searchParam: '',
      textarea1: '',
      editor: null,
      html: '',
      flag: false,
      utilId: '',
      toolbarConfig: {
        // insertKeys: {
        //   index: 35, // 插入的位置，基于当前的 toolbarKeys
        //   keys: ['createMenu']
        // }
        toolbarKeys: [
          'codeBlock',
          'fullScreen'
        ]
      },
      editorConfig: {
        placeholder: '请输入代码...（支持Markdown语法）',

      },
      mode: 'default',
    }
  },
  methods: {
    chongzhi() {
      this.searchParam = ''
    },
    openEditor() {
      if(this.flag) {
        this.textarea1 = ''
        this.html = ''
        this.utilId = ''
        // this.flag = false
      }
      this.dialogFormVisible = true
      this.flag = false
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    onChange() {},
    getForm() {
      // console.log('utilId===',id)
      // bus.$emit("clear",true)
      // const description = this.$store.state.descriptioneditorhtml
      const code = this.html
      // console.log(description,'+',code)
      if(code === null || code === '<p><br></p>' || code === '' || this.textarea1 === null || this.textarea1 === '') {
        this.$notify({
          title: '警告',
          message: '描述或代码不能为空',
          type: 'warning'
        });
      } else {
        if(this.utilId === '' || this.utilId === null) {
          this.saveUtil(this.textarea1 ,code)
        } else {
          // console.log('editUtil...',this.utilId)
          // console.log('editUtil...',this.textarea1)
          // console.log('editUtil...',this.html)
          this.editUtil(this.utilId,this.textarea1,this.html)
        }

        this.textarea1 = ''
        this.html = ''
        this.dialogFormVisible = false
      }
    },
    editUtil(id,description,code) {
      const util = {
        id: id,
        description: description,
        utilsCode: code
      }
      updateUtil(util).then(
          res => {
            // console.log(res)
            this.getUtilsList('')
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
          }
      )
    },
    saveUtil(description ,code) {
      const util = {
        description: description,
        utilsCode: code
      }
      addUtil(util).then(
          res => {
            // console.log(res)
            this.getUtilsList('')
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
          }
      )
    },
    getUtilsList(param) {
      getUtils(param).then (
          res => {
            // console.log(res)
            this.utilList = res.data
          }
      )
    },
    delUtil(id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delUtil1(id).then(
            res => {
              // console.log(res)
              this.getUtilsList('')
              this.$notify({
                title: '成功',
                message: res.data,
                type: 'success'
              });
            }
        )
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    edit(description,code,id) {
      this.textarea1 = description
      this.html = code
      this.flag = true
      this.utilId = id
      this.dialogFormVisible = true
    },

    toDetailUtil(id,description,code) {
      this.$router.push({
        name: 'detailUtil',
        params: {
          id: id
        }
      })
      this.$store.commit('utilDescription',description)
      this.$store.commit('utilCode',code)
    }
  },
  created() {
    this.getUtilsList(this.searchParam)
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }

}
</script>

<style scoped>
.cu{
  padding: 10px;
  background-color: #F0F2F5;
  width: 100%;
}
.center {
  width: 1200px;
  background-color: #FFFFFF;
  margin: 0 auto;
  padding: 20px;
}
.top1 {
  width: 1200px;
  background-color: #FFFFFF;
  margin: 0 auto;
  padding: 10px;
}
.utils {
  display: inline-block;
  width: 1160px;
}
.utils li {
  list-style: none;
}
.utils .util li:hover {
  color: #1890FF;
  cursor: pointer
}
.utils .util p:hover {
  color: #1890FF;
  cursor: pointer
}

a {
  text-decoration: none;
  color: #000000;
}
.card {
  /*width: 980px;*/
  width: 1160px;
  margin-bottom: 10px;
}
.card:hover {
  background-color: #F0F2F5;
}
p {
  /*text-indent: 2em;*/
  line-height: 1.5;
}
.three li {
  padding-right: 10px;
  display: inline-block;
  list-style: none;
}

.utils .left {
  overflow: hidden;
  display: inline-block;
  width: 680px;
  height: 90px;
}
.utils .right {
  overflow: hidden;
  display: inline-block;
  width: 250px;
  height: 90px;
}
.bottom li {
  padding-right: 25px;
  display: inline-block;
}
.right {
  float: right;
}
</style>