import request from "@/utils/request";

//发布问题
export function publishQuestion(question) {
    return request({
        method: 'post',
        url: '/question/api/v1/publishQuestion',
        data: question
    })
}

//查询问题标签
export function getTagList() {
    return request({
        method: 'get',
        url: '/questionTag/api/v1/getTagList',
    })
}

//查询问题列表
export function getQuestionList(questionTagId,content) {
    return request({
        method: 'get',
        url: '/question/api/v1/getQuestionList',
        params: {
            questionTagId: questionTagId,
            content: content
        }
    })
}

//根据id查询问题详情
export function getQuestionById(questionId) {
    return request({
        method: 'get',
        url: '/question/api/v1/getQuestionById',
        params: {
            questionId: questionId
        }
    })
}

//判断当前登录用户是否对某个问题点赞
export function isLike(id) {
    return request({
        method: 'get',
        url: '/questionLike/api/v1/isLike',
        params: {
            questionId: id
        }
    })
}

//判断问题是否在该用户的收藏列表里
export function isCollect(id) {
    return request({
        method: 'get',
        url: '/questionCollect/api/v1/isCollect',
        params: {
            questionId: id
        }
    })
}

//点赞/取消赞
export function setQuestionLike(id) {
    return request({
        method: 'post',
        url: '/questionLike/api/v1/setQuestionLike',
        params: {
            questionId: id
        }
    })
}

//收藏/取消收藏问题
export function collectQuestion(id) {
    return request({
        method: 'post',
        url: '/questionCollect/api/v1/collectQuestion',
        params: {
            questionId: id
        }
    })
}

//对文问题评论
export function writeComment(questionComment) {
    return request({
        method: 'post',
        url: '/questionComment/api/v1/writeComment',
        data: questionComment
    })
}

//获取文章评论
export function listComments(questionId) {
    return request({
        method: 'get',
        url: '/questionComment/api/v1/listComments',
        params: {
            questionId: questionId
        }
    })
}