<template>
  <div class="mc">
    <div class="list">
      <ul class="second">
        <li v-for="article in articleList" :key="article.id">
          <el-card class="card" shadow="never">
            <h3 class="hand" style="margin-left: 5px;display: inline-block" @click="xiangqing(article.id)">{{ article.title }}</h3>

            <el-button @click="quxiaoCollect(article.id)" style="display:inline-block;float: right" size="mini" type="warning" round>取消收藏</el-button>

            <div class="content">
              <div style="width: 134px;height: 84px;display: inline-block" class="simg hand" @click="xiangqing(article.id)">
                <img style="width: 134px;height: 84px;border-radius: 5px;display: inline-block" :src="article.cover">
              </div>
              <div style="width: 800px;height: 84px;display: inline-block;float: right" class="cr">
                <p style="width: 800px" class="description">{{ article.articleAbstract }}
                </p>
                <ul class="three">
                  <span>
                    <li>
                    <div style="margin-top: 15px;height: 40px;" class="demo-basic--circle avatar">
                      <el-avatar style="vertical-align: middle" :size="40" :src="article.userInformation.headPortrait"></el-avatar>
                    </div>
                  </li>
                  <li>
                    <span>{{ article.userInformation.nickName }}</span>
                  </li>
                  <li v-for="tag in article.articleTags" :key="tag.id">
                    <el-tag size="small" style="margin-left: 5px">{{ tag.name }}</el-tag>
                  </li>
                  </span>

                  <span style="float: right;margin-top: 23px">
                    <li>
                    <i style="color: #42A7FF" class="iconfont">&#xe69a;</i>
                    <span> {{ article.lookNumber }}</span>
                    <span v-if="article.lookNumber === null || article.lookNumber === ''"> 0</span>
                  </li>
                  <li style="margin-left: 10px">
                    <i style="color: #42A7FF" class="iconfont">&#xe636;</i>
                    <span> {{ article.collectNumber }}</span>
                    <span v-if="article.collectNumber === null || article.collectNumber === ''"> 0</span>
                  </li>
                  <li style="margin-left: 10px">
                    <i style="color: #42A7FF" class="iconfont">&#xec8c;</i>
                    <span> {{ article.likeNumber }}</span>
                    <span v-if="article.likeNumber === null || article.likeNumber === ''"> 0</span>
                  </li>
                  <li style="margin-left: 10px">
                    <i style="color: #42A7FF" class="iconfont">&#xe8c5;</i> {{ article.updateTime }}
                  </li>
                  </span>


                </ul>

              </div>
            </div>
          </el-card>
        </li>

      </ul>

    </div>
  </div>
</template>

<script>
import {qxCollect, getMyCollectArticle} from "@/api/myArticle";

export default {
  name: "MyCollect",
  data() {
    return {
      articleList: [],
    }
  },
  methods: {
    getMyCollect() {
      getMyCollectArticle().then(
          res => {
            this.articleList = res.data
            // console.log(res)
          }
      )
    },
    xiangqing(id) {
      // console.log(id)
      this.$router.push({
        name: 'detailpage',
        params: {
          id:id
        }
      })
    },
    quxiaoCollect(id) {
      this.$confirm('取消收藏, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        qxCollect(id).then(
            res => {
              this.getMyCollect()
              this.$notify({
                title: '成功',
                message: res.data,
                type: 'success'
              });
            }
        )
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    }
  },
  created() {
    this.getMyCollect()
  }
}
</script>

<style scoped>
.mc {
  float: right;
  /*margin-left: 10px;*/
  width: 1000px;
  background-color: #FFFFFF;
}
.first li {
  padding: 0 24px 5px 0;
  display: inline-block;
  list-style: none;
}
a {
  text-decoration: none;
  color: #000000;
}
.card {
  width: 1000px;
  margin-bottom: 10px;
}
.card:hover {
  background-color: #F0F2F5;
}
.second {
  display: inline-block;
  width: 1000px;
}
.second li {
  list-style: none;
}
.list {
  float: left;
  display: inline-block;
  width: 1000px;
}
.right /deep/  .el-calendar-table .el-calendar-day{
  height: 45px;
}
.content {
  margin-top: 10px;
}
p {
  /*text-indent: 2em;*/
  line-height: 1.5;
}
.three li {
  padding-right: 10px;
  display: inline-block;
  list-style: none;
}
.hand:hover {
  cursor: pointer;
}
</style>