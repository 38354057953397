<template>
  <div class="qe" style="border: 1px solid #ccc;background-color: #FFFFFF">
    <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
        :mode="mode"
    />
    <Editor
        style="min-height: 120px; overflow-y: hidden;"
        v-model="html"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="onCreated"
        @onChange="onChange"
    />
  </div>
</template>

<script>
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'

export default {
  name: "CommentEditor",
  components: {Editor, Toolbar},
  data() {
    return {
      editor: null,
      html: '',
      toolbarConfig: {
        excludeKeys: [
          '|',
          'group-indent',
          'todo',
          'undo',
          'redo',
          'fullScreen',
          'group-image',
          'group-image',
          'group-video',
          'insertLink',
          'divider',
          'italic'
        ]
      },
      editorConfig: {
        scroll: false,
        placeholder: '请输入友善的评论...（支持Markdown语法）',
      },
      mode: 'default', // or 'simple'
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    onChange(editor) {
      const newHtml = editor.getHtml()
      this.$emit('change', newHtml);
      editor.getConfig.value = newHtml
    }
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },

}

</script>

<style scoped>

</style>