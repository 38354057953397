import Vue from "vue";
//模板解析顺序需要在这里使用Vuex
import Vuex from 'vuex';

Vue.use(Vuex);
//准备工作，比如数据的准备
const actions = {
    test(context, value) {
        context.commit('TEST', value);
    },
    test1(context) {
        //模拟向后台发送请求获取数据
        const value = 2;

        context.commit('TEST',value);
    }
}
//执行的动作
const mutations = {
    descriptioneditorhtml(state, value) {
        state.descriptioneditorhtml = value
    },
    codeeditorhtml(state, value) {
        state.codeeditorhtml = value
    },
    articletext(state, value) {
        state.articletext = value
    },
    articletitle(state, value) {
        state.articletitle = value
    },
    articlecontent(state, value) {
        state.articlecontent = value
    },
    utilDescription(state, value) {
        state.utilDescription = value
    },
    utilCode(state, value) {
        state.utilCode = value
    },
    bianjiArticleId(state, value) {
        state.bianjiArticleId = value
    }
}
//共享数据
const state = {
    bianjiArticleId: '',
    descriptioneditorhtml: '',
    codeeditorhtml: '',
    articletext: '',
    articletitle: '',
    articlecontent: '',
    utilDescription: '',
    utilCode: ''
}
//对数据进行加工，其实就是store的计算属性
const getters = {
    get10bei() {
        return state.num * 10;
    }
}

export default new Vuex.Store({
    actions,
    mutations,
    state,
    getters
})
