<template>
  <div class="cm">
    <div class="top">
      <ul>
        <a href="#">
          <li style="margin-right: 30px;">博客评论</li>
        </a>
        <a href="#">
          <li style="margin-right: 30px;">问答评论</li>
        </a>
      </ul>
    </div>
    <hr>
    <div class="body">
      <div class="bar">
        <ul>
          <a href="#"><li>我的文章评论</li></a>
          <a href="#"><li>我发表的评论</li></a>
        </ul>
      </div>
    </div>

    <el-empty :image-size="380"></el-empty>

  </div>
</template>

<script>
export default {
  name: "CommontManage"
}
</script>

<style scoped>
.cm {
  width: 100%;
  background-color: #FFFFFF;
}
.top {
  padding: 0 20px 0 20px;
  width: 100%;
  height: 56px;
  line-height: 56px;
}
a {
  text-decoration: none;
  color: black;
}
li {
  list-style: none;
  display: inline-block;
}
li:hover {
  color: #1E80FF;
}
.top ul {
  float: left;
  width: 400px;
}
.bar {
  padding: 0 20px 0 20px;
}
.bar ul {
  height: 56px;
  line-height: 56px;

}
.bar li {
  padding: 0 10px 0 10px;
  color: #86909C;
}
.bar li:hover {
  color: #1E80FF;
}
</style>
