<template>
  <div class="tl">
    <div class="top" style="margin-bottom: 10px;text-align: center">
      <span style="font-size: 25px;color: rgba(0, 0, 0, 0.45)">
        制定任务计划，帮助您更高效的学习！
      </span>
    </div>
    <div class="center">
      <div class="create">
        <span style="color: rgba(0, 0, 0, 0.45)">
          任务内容：
        </span>
        <el-input
            style="width: 700px"
            placeholder="请输入内容"
            v-model="content"
            size="medium">
        </el-input>
        <el-date-picker
            style="width: 195px;margin-left: 10px"
            v-model="deadline"
            type="datetime"
            size="medium"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="任务截止时间">
        </el-date-picker>
        <div style="float: right">
          <el-button size="medium" @click="chongzhi">重置</el-button>
          <el-button type="primary" size="medium" @click="createTask(content,deadline)">创建</el-button>
        </div>

      </div>
      <el-divider><h3>未完成</h3></el-divider>
      <div class="unfinished">
        <el-table
            :data="tableData1"
            style="width: 100%">
          <el-table-column
              prop="task"
              label="任务内容"
              align="center">
          </el-table-column>
          <el-table-column
              align="center"
              prop="deadline"
              label="截止日期">
          </el-table-column>
          <el-table-column
              align="center"
              label="距离截止时间还剩">
            <template slot-scope="scope">
              <el-statistic
                  ref="statistic"
                  @finish="hilarity(scope.row)"
                  format="HH:mm:ss"
                  :value="Date.parse(scope.row.deadline)"
                  time-indices
              >
              </el-statistic>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="primary" icon="el-icon-edit" circle @click="editDeadline(scope.row)"></el-button>
              <el-button type="danger" icon="el-icon-delete" circle @click="deleteTask(scope.row)"></el-button>
              <el-button type="success" icon="el-icon-check" circle @click="updateTaskStatus(scope.row)"></el-button>
            </template>
          </el-table-column>

        </el-table>
      </div>
      <el-divider><h3>已完成</h3></el-divider>
      <div class="finished">
        <el-table
            :data="tableData2"
            style="width: 100%">
          <el-table-column
              prop="task"
              label="任务内容"
              align="center">
          </el-table-column>
          <el-table-column
              prop="finishTime"
              label="完成日期"
              align="center">
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="danger" icon="el-icon-delete" circle @click="deleteTask(scope.row)"></el-button>
              <el-button type="warning" icon="el-icon-close" circle @click="updateTaskStatus(scope.row)"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-dialog title="编辑" :visible.sync="dialogFormVisible" custom-class="saveAsDialog">
        <h3 style="font-size: 14px;font-weight: 400;display: inline-block">点击修改截止时间：</h3>
        <el-date-picker
            size="small"
            style="width: 195px;margin-left: 10px"
            v-model="updateDeadline"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="截止时间">
        </el-date-picker>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="submitUpdate">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {addTask, getTasks, delTask, updateStatus, updateDeadline} from "@/api/myTasks";

export default {
  name: "TodoList",
  data() {
    return {
      content: '',
      deadline: '',
      tableData1: [],
      tableData2: [],
      dialogFormVisible: false,
      updateDeadline: '',
      updateId: '',
      formLabelWidth: '200px'
    }
  },
  created() {
    this.getTaskList()
  },
  methods: {
    chongzhi() {
      this.content = ''
      this.deadline = ''
    },
    submitUpdate() {
      // console.log('===',this.updateDeadline,'===',this.updateId)
      updateDeadline(this.updateId,this.updateDeadline).then(
          res => {
            this.getTaskList()
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
          }
      )
      this.dialogFormVisible = false
    },
    editDeadline(row) {
      this.updateId = row.id
      this.updateDeadline = row.deadline
      this.dialogFormVisible = true
    },
    updateTaskStatus(row){
      updateStatus(row.id, row.taskStatus).then(
          res => {
            this.getTaskList()
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
          }
      )
    },
    deleteTask(row){
      // console.log(row)
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(
          () => {
            delTask(row.id).then(
                res => {
                  this.getTaskList()
                  this.$notify({
                    title: '成功',
                    message: res.data,
                    type: 'success'
                  });
                }
            )
          }
      )
    },
    getTaskList() {
      getTasks().then(
          res => {
            // console.log(res)
            this.tableData1 = res.data.uncompleted
            this.tableData2 = res.data.completed
          }
      )
    },
    createTask(content, deadline) {
      const task = {
        task: content,
        deadline: deadline
      }
      addTask(task).then(
          res => {
            this.getTaskList()
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            })
            this.content = ''
            this.deadline = ''
          }
      )
    },
    hilarity() {
    },
  }
}
</script>

<style scoped>
.tl {
  padding: 10px;
  background-color: #F0F2F5;
  width: 100%;
}
.center {
  width: 1200px;
  background-color: #FFFFFF;
  margin: 0 auto;
  padding: 20px;
}
.top {
  width: 1200px;
  background-color: #FFFFFF;
  margin: 0 auto;
  padding: 10px;
}
.create {
  margin-bottom: 50px;
}

.unfinished {
  margin-bottom: 50px;
}
/deep/ .saveAsDialog {
  width: 480px !important;
}
</style>