<template>
  <div class="db">
    <div class="jbxx information">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>
            <h3 style="line-height: 100%">
              基本信息
              <el-button style="float: right; padding: 3px 0" type="text" @click="edit1">编辑</el-button>
            </h3>
          </span>

        </div>
        <ul>
          <li>
            <span class="left">用户昵称</span>
            <span class="right">{{nickName}}</span>
          </li>
          <li>
            <span class="left">性别</span>
            <span class="right">{{sex}}</span>
          </li>
          <li>
            <span class="left">个人简介</span>
            <span class="right"><p>{{introduction}}</p></span>
          </li>
          <li>
            <span class="left">所在地区</span>
            <span class="right">{{address}}</span>
          </li>
          <li class="last">
            <span class="left">出生日期</span>
            <span class="right">{{birthday}}</span>
          </li>
        </ul>
      </el-card>
    </div>
    <div class="jyxx information">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>
            <h3 style="line-height: 100%">
              教育信息
              <el-button style="float: right; padding: 3px 0" type="text" @click="edit2">编辑</el-button>
            </h3>
          </span>
        </div>
        <ul>
          <li>
            <span class="left">学校名称</span>
            <span class="right">{{ school }}</span>
          </li>
          <li>
            <span class="left">专业</span>
            <span class="right">{{ zhuanye }}</span>
          </li>
          <li>
            <span class="left">入学时间</span>
            <span class="right">{{ timeSchool }}</span>
          </li>
          <li class="last">
            <span class="left">学历</span>
            <span class="right">{{ xueli }}</span>
          </li>
        </ul>
      </el-card>
    </div>
    <div class="gzxx information">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>
            <h3 style="line-height: 100%">
              工作信息
              <el-button style="float: right; padding: 3px 0" type="text" @click="edit3">编辑</el-button>
            </h3>
          </span>
        </div>
        <ul>
          <li>
            <span class="left">公司名称</span>
            <span class="right">{{ company }}</span>
          </li>
          <li>
            <span class="left">职位名称</span>
            <span class="right">{{ zhiwei }}</span>
          </li>
          <li class="last">
            <span class="left">所属行业</span>
            <span class="right">{{ hangye }}</span>
          </li>
        </ul>
      </el-card>
    </div>
    <div class="jnbq information">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>
            <h3 style="line-height: 100%">
              技能标签
              <el-button style="float: right; padding: 3px 0" type="text" @click="edit4">编辑</el-button>
            </h3>
          </span>
        </div>
        <span v-if="myTags.length === 0">暂未设置标签</span>
        <ul>
          <li v-for="tag in myTags" :key="tag.id"><el-tag>{{ tag.tag }}</el-tag></li>
        </ul>
      </el-card>
    </div>

    <el-dialog title="基本信息" :visible.sync="dialogFormVisible1" custom-class="saveAsDialog">
      <div>
        <h3 style="font-size: 16px;font-weight: 400;display: inline-block">用户昵称：</h3>
        <el-input
            style="width: 250px"
            size="small"
            placeholder="请输入内容"
            v-model="nickNameUpdate"
            clearable>
        </el-input>
      </div>
      <div style="margin-top: 20px">
        <h3 style="font-size: 16px;font-weight: 400;display: inline-block;width: 80px">性别：</h3>
        <el-radio v-model="radio" label="1">男</el-radio>
        <el-radio v-model="radio" label="2">女</el-radio>
      </div>
      <div style="margin-top: 20px">
        <h3 style="font-size: 16px;font-weight: 400;display: inline-block">个人简介：</h3>
        <el-input
            style="width: 250px;vertical-align: middle"
            type="textarea"
            autosize
            placeholder="请输入内容"
            v-model="introductionUpdate">
        </el-input>
      </div>
      <div style="margin-top: 20px">
        <h3 style="font-size: 16px;font-weight: 400;display: inline-block">所在地区：</h3>
        <el-input
            style="width: 250px"
            size="small"
            placeholder="请输入内容"
            v-model="addressUpdate"
            clearable>
        </el-input>
      </div>
      <div style="margin-top: 20px">
        <h3 style="font-size: 16px;font-weight: 400;display: inline-block">出生日期：</h3>
        <el-date-picker
            style="width: 250px"
            size="small"
            v-model="birthdayUpdate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
        </el-date-picker>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible1 = false">取 消</el-button>
        <el-button size="small" type="primary" @click="sure1">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="教育信息" :visible.sync="dialogFormVisible2" custom-class="saveAsDialog">
      <div>
        <h3 style="font-size: 16px;font-weight: 400;display: inline-block">学校名称：</h3>
        <el-input
            style="width: 250px"
            size="small"
            placeholder="请输入内容"
            v-model="schoolUpdate"
            clearable>
        </el-input>
      </div>
      <div style="margin-top: 20px">
        <h3 style="font-size: 16px;font-weight: 400;display: inline-block;width: 80px">专业：</h3>
        <el-input
            style="width: 250px"
            size="small"
            placeholder="请输入内容"
            v-model="zhuanyeUpdate"
            clearable>
        </el-input>
      </div>
      <div style="margin-top: 20px">
        <h3 style="font-size: 16px;font-weight: 400;display: inline-block">入学时间：</h3>
        <el-date-picker
            style="width: 250px"
            size="small"
            v-model="timeSchoolUpdate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
        </el-date-picker>
      </div>
      <div style="margin-top: 20px">
        <h3 style="font-size: 16px;font-weight: 400;display: inline-block;width: 80px">学历：</h3>
        <el-input
            style="width: 250px"
            size="small"
            placeholder="请输入内容"
            v-model="xueliUpdate"
            clearable>
        </el-input>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button size="small" type="primary" @click="sure2">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="工作信息" :visible.sync="dialogFormVisible3" custom-class="saveAsDialog">
      <div>
        <h3 style="font-size: 16px;font-weight: 400;display: inline-block">公司名称：</h3>
        <el-input
            style="width: 250px"
            size="small"
            placeholder="请输入内容"
            v-model="companyUpdate"
            clearable>
        </el-input>
      </div>
      <div style="margin-top: 20px">
        <h3 style="font-size: 16px;font-weight: 400;display: inline-block">职位名称：</h3>
        <el-input
            style="width: 250px"
            size="small"
            placeholder="请输入内容"
            v-model="zhiweiUpdate"
            clearable>
        </el-input>
      </div>
      <div style="margin-top: 20px">
        <h3 style="font-size: 16px;font-weight: 400;display: inline-block">所属行业：</h3>
        <el-input
            style="width: 250px"
            size="small"
            placeholder="请输入内容"
            v-model="hangyeUpdate"
            clearable>
        </el-input>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible3 = false">取 消</el-button>
        <el-button size="small" type="primary" @click="sure3">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="技能标签" :visible.sync="dialogFormVisible4" custom-class="saveAsDialog">
      <div>
        <h3 style="font-size: 16px;font-weight: 400;display: inline-block">标签选择：</h3>
        <el-select style="width: 80%" size="small" value-key="id" v-model="myTagsUpdate" multiple placeholder="请选择">
          <el-option
              v-for="item in allTags"
              :key="item.id"
              :label="item.tag"
              :value="item.id">
          </el-option>
        </el-select>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible4 = false">取 消</el-button>
        <el-button size="small" type="primary" @click="sure4">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getMySelfInformation, updateMySelfInformation} from "@/api/userInformation";
import {getMySelfTags, getAllTags, setUserTagRelation} from "@/api/myTags";
import bus from "@/store/bus";

export default {
  name: "DataBody",
  data() {
    return {
      dialogFormVisible1: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      dialogFormVisible4: false,
      id: '',
      userId: '',
      nickName: '',
      nickNameUpdate: '',
      radio: '',
      sex: '',
      introduction: '',
      introductionUpdate: '',
      address: '',
      addressUpdate: '',
      birthday: '',
      birthdayUpdate: '',
      school: '',
      schoolUpdate: '',
      zhuanye: '',
      zhuanyeUpdate: '',
      timeSchool: '',
      timeSchoolUpdate: '',
      xueli: '',
      xueliUpdate: '',
      company: '',
      companyUpdate: '',
      zhiwei: '',
      zhiweiUpdate: '',
      hangye: '',
      hangyeUpdate: '',
      allTags: [],
      myTags: [],
      myTagsUpdate: [],
      headPortrait: '',
    }
  },
  watch: {
    nickName(newVal) {
      bus.$emit('nickNameChange',newVal)
    },
    introduction(newVal) {
      bus.$emit('introductionChange',newVal)
    },
    myTags(newVal) {
      bus.$emit('myTagsChange',newVal)
    },
    userId(newVal) {
      bus.$emit('userId',newVal)
    },
    headPortrait(newVal) {
      bus.$emit('headPortrait',newVal)
    }
  },
  methods: {
    getMyTags() {
      getMySelfTags().then(
          res => {
            this.myTags = res.data
            //设置回显标签
            for(let i=0;i < res.data.length; i++) {
              this.myTagsUpdate[i] = res.data[i].id
            }
            // console.log(this.myTagsUpdate)
          }
      )
    },
    getInformation() {
      getMySelfInformation().then(
          res => {
            this.id = res.data.id
            this.nickName = res.data.nickName
            if(res.data.userSex === 'NAN') {
              this.sex = '男'
            } else if(res.data.userSex === 'NV') {
              this.sex = '女'
            } else {
              this.sex = ''
            }
            if(res.data.individualResume === null || res.data.individualResume === '') {
              this.introduction = '这个人很懒，什么都没有留下'
            } else {
              this.introduction = res.data.individualResume
            }
            this.address = res.data.address
            this.birthday = res.data.birthday
            this.school = res.data.school
            this.zhuanye = res.data.major
            this.timeSchool = res.data.timeEnrollment
            this.xueli = res.data.enducationBackground
            this.company = res.data.company
            this.zhiwei = res.data.positionName
            this.hangye = res.data.industry
            this.userId = res.data.userId
            this.headPortrait  =res.data.headPortrait
            // console.log(res.data)
          }
      )
    },
    edit1() {
      this.nickNameUpdate = this.nickName
      if(this.sex === '男') {
        this.radio = '1'
      } else if(this.sex === '女') {
        this.radio = '2'
      } else {
        this.radio = ''
      }
      this.introductionUpdate = this.introduction
      this.addressUpdate = this.address
      this.birthdayUpdate = this.birthday
      this.dialogFormVisible1 = true
    },
    edit2() {
      this.schoolUpdate = this.school
      this.zhuanyeUpdate = this.zhuanye
      this.timeSchoolUpdate = this.timeSchool
      this.xueliUpdate = this.xueli
      this.dialogFormVisible2 = true
    },
    edit3() {
      this.companyUpdate = this.company
      this.zhiweiUpdate = this.zhiwei
      this.hangyeUpdate = this.hangye
      this.dialogFormVisible3 = true
    },
    edit4() {
      //获取所有标签
      getAllTags().then(
          res => {
            // console.log(res.data)
            this.allTags = res.data
          }
      )
      //设置回显标签
      // for(let i=0;i < this.myTags.length; i++) {
      //   console.log(this.myTags[i].id)
      //   this.myTagsUpdate[i] = this.myTags[i].id
      // }
      this.dialogFormVisible4 = true
    },
    sure1() {
      this.dialogFormVisible1 = false
      var userSex;
      if(this.radio === '1') {
        userSex = 'NAN'
      } else if(this.radio === '2') {
        userSex = 'NV'
      } else {
        userSex = 'NULL'
      }
      const userInformation = {
        id: this.id,
        nickName: this.nickNameUpdate,
        userSex: userSex,
        individualResume: this.introductionUpdate,
        address: this.addressUpdate,
        birthday: this.birthdayUpdate
      }
      updateMySelfInformation(userInformation).then(
          res => {
            this.getInformation()
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
          }
      )
    },
    sure2() {
      this.dialogFormVisible2 = false
      const userInformation = {
        id: this.id,
        school: this.schoolUpdate,
        major: this.zhuanyeUpdate,
        timeEnrollment: this.timeSchoolUpdate,
        enducationBackground: this.xueliUpdate
      }
      updateMySelfInformation(userInformation).then(
          res => {
            this.getInformation()
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
          }
      )
    },
    sure3() {
      this.dialogFormVisible3 = false
      const userInformation = {
        id: this.id,
        company: this.companyUpdate,
        positionName: this.zhiweiUpdate,
        industry: this.hangyeUpdate
      }
      updateMySelfInformation(userInformation).then(
          res => {
            this.getInformation()
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
          }
      )
    },
    sure4() {
      this.dialogFormVisible4 = false
      // console.log(this.myTagsUpdate)
      setUserTagRelation(this.myTagsUpdate).then(
          res => {
            this.getMyTags()
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
          }
      )
    },
  },
  created() {
    this.getInformation()
    this.getMyTags()
  }
}
</script>

<style scoped>
.information {
  margin-top: 10px;
  border-radius: 5px;
  background-color: #FFFFFF;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}

.box-card {
  width: 100%;
}
li {
  overflow: hidden;
  list-style: none;
  margin-bottom: 32px;
}
.jnbq li {
  list-style: none;
  display: inline-block;
  margin: 0 10px 0 0;
}
.left {
  display: inline-block;
  margin-right: 40px;
  width: 64px;
  height: 24px;
}
.right {
  display: inline-block;
  width: 854px;
  float: right;
}
.last {
  margin-bottom: 0;
}

/deep/ .saveAsDialog {
  width: 480px !important;
}

</style>
