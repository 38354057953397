<template>
  <div class="bg">
    <div>
      <div @click="zan" class="hand" style="width: 50px;height: 50px;border-radius: 50%;background-color: #fff;text-align: center;line-height: 50px">
        <i v-if="isOrNoLike === true" class="iconfont" style="color: #1E80FF">&#xec8c;</i>
        <i v-else class="iconfont">&#xec8c;</i>
      </div>
    </div>
    <div style="margin-top: 20px">
      <div class="hand" style="width: 50px;height: 50px;border-radius: 50%;background-color: #fff;text-align: center;line-height: 50px">
        <i class="iconfont">&#xe624;</i>
      </div>
    </div>
    <div style="margin-top: 20px">
      <div @click="collect" class="hand" style="width: 50px;height: 50px;border-radius: 50%;background-color: #fff;text-align: center;line-height: 50px">
        <i v-if="isOrNoCollect === true" class="iconfont" style="color: #1E80FF">&#xe636;</i>
        <i v-else class="iconfont">&#xe636;</i>
      </div>
    </div>
    <div class="backtop" style="height: 800px;overflow-y: auto">
      <el-backtop target=".bg" :visibility-height=400></el-backtop>
    </div>

  </div>
</template>

<script>
import {isLike, setArticleLike, isCollect, collectArticle} from "@/api/myArticle";
import bus from "@/store/bus";

export default {
  name: "ButtonGroup",
  data() {
    return {
      isOrNoLike: false,  //是否点赞
      isOrNoCollect: false, //是否收藏
    }
  },
  methods: {
    //判断文章是否被点赞
    isLikeArticle(id) {
      isLike(id).then(
          res => {
            this.isOrNoLike = res.data
          }
      )
    },
    //判断文章是否被收藏
    isCollectArticle(id) {
      isCollect(id).then(
          res => {
            this.isOrNoCollect = res.data
          }
      )
    },
    //点赞/取消赞
    zan() {
      const articleId = this.$route.params.id
      setArticleLike(articleId).then(
          res => {
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
            this.isLikeArticle(articleId)
            bus.$emit('flushLike',true)
          }
      )
    },
    //收藏/取消收藏
    collect() {
      const articleId = this.$route.params.id
      collectArticle(articleId).then(
          res => {
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
            this.isCollectArticle(articleId)
            bus.$emit('flushCollect',true)
          }
      )
    }
  },
  created() {
    const articleId = this.$route.params.id
    this.isLikeArticle(articleId)
    this.isCollectArticle(articleId)
  }
}
</script>

<style scoped>
.bg {
  position: fixed;
  margin-top: 150px;
}
.hand:hover {
  cursor:pointer;
}
</style>
