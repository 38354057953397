<template>
  <div class="na">
    <div class="title">
      <h3 style="font-size: 15px">最新文章</h3>
    </div>
    <ul>
      <li @click="xiangqing(article.id)" v-for="article in newArticleList" :key="article.id">{{ article.title }}
        <span style="float: right">
          <i class="iconfont">&#xe8c5;</i>
        {{ article.updateTime }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import {getArtcleById, personalNewList} from "@/api/myArticle";
export default {
  name: "NewArticle",
  data() {
    return {
      newArticleList: []
    }
  },

  methods: {
    xiangqing(id) {
      this.$router.push({
        name: 'detailpage',
        params: {
          id:id
        }
      })
    }
  },
  created() {
    const articleId = this.$route.params.id
    getArtcleById(articleId).then(
        res => {
          // console.log(res)
          personalNewList(res.data.userId).then(
              res => {
                this.newArticleList = res.data
              }
          )
        }
    )
  }
}
</script>

<style scoped>
.na {
  margin-top: 10px;
  border-radius: 4px;
  background-color: white;
}
.title {
  padding: 10px;
  height: 38px;
  background-color: #D6ECF8;
}
a {
  text-decoration: none;
  color: black;
}
li {
  overflow: hidden;
  padding: 10px;
  list-style: none;
  color: #555666;
  font-size: 14px;
}
li:hover {
  color: #1E80FF;
  cursor: pointer;
}
</style>
