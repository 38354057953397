<template>
  <div class="ta typearea">
    <PersonalSidebar></PersonalSidebar>
    <router-view></router-view>
  </div>
</template>

<script>
import PersonalSidebar from "@/components/personalcenter/typearea/sidebar/PersonalSidebar";
export default {
  name: "TypeArea",
  components: { PersonalSidebar}
}
</script>

<style scoped>
.ta {
  overflow: hidden;
  padding: 10px 0;
}
.typearea {
  margin: 0 auto;
  width: 1200px;
  /*height: 500px;*/
  /*background-color: skyblue;*/
}
</style>
