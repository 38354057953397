<template>
  <div class="ps">
    <DataHeader></DataHeader>
    <DataBody></DataBody>
  </div>
</template>

<script>
import DataHeader from "@/components/personalcenter/typearea/show/personaldata/DataHeader";
import DataBody from "@/components/personalcenter/typearea/show/personaldata/DataBody";
export default {
  name: "PersonalData",
  components: {DataBody, DataHeader}
}
</script>

<style scoped>
.ps {
  float: right;
  margin-left: 10px;
  width: 1000px;
}
</style>
