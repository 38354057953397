<template>
  <div class="cu">
    <el-button size="medium" style="width: 100%;margin-bottom: 20px" type="primary">添加一条Bug记录</el-button>

    <ul class="status" style="margin-bottom: 20px;margin-left: 20px">
      <li><a href="#">全部</a></li>
      <li><a href="#">已解决</a></li>
      <li><a href="#">未解决</a></li>
    </ul>

    <el-input
        size="medium "
        style="width: 900px;margin-right: 10px"
        placeholder="请输入内容"
        v-model="input"
        clearable>
    </el-input>
    <el-button style="margin-bottom: 20px" type="primary" size="medium">搜索</el-button>
    <el-divider>Bug记录展示</el-divider>
    <div class="list">
      <ul class="utils">
        <li>
          <el-card class="card" shadow="never">
            <div class="box">
              <div class="left">
                <p>Caused by: org.springframework.beans.factory.BeanCreationException: Error creating bean with name 'serverEndpointExporter' defined in class path resource [com/gxxw/video/modules/from/home/socket/WebSocketConfig.class]: Invocation of init method failed; nested exception is java.lang.IllegalStateException: javax.websocket.server.ServerContainer not available
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.initializeBean(AbstractAutowireCapableBeanFactory.java:1778)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.doCreateBean(AbstractAutowireCapableBeanFactory.java:593)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.createBean(AbstractAutowireCapableBeanFactory.java:515)
                  at org.springframework.beans.factory.support.AbstractBeanFactory.lambda$doGetBean$0(AbstractBeanFactory.java:320)
                  at org.springframework.beans.factory.support.DefaultSingletonBeanRegistry.getSingleton(DefaultSingletonBeanRegistry.java:222)
                  at org.springframework.beans.factory.support.AbstractBeanFactory.doGetBean(AbstractBeanFactory.java:318)
                  at org.springframework.beans.factory.support.AbstractBeanFactory.getBean(AbstractBeanFactory.java:199)
                  at org.springframework.beans.factory.support.DefaultListableBeanFactory.preInstantiateSingletons(DefaultListableBeanFactory.java:845)
                  at org.springframework.context.support.AbstractApplicationContext.finishBeanFactoryInitialization(AbstractApplicationContext.java:877)
                  at org.springframework.context.support.AbstractApplicationContext.refresh(AbstractApplicationContext.java:549)
                  at org.springframework.boot.SpringApplication.refresh(SpringApplication.java:742)
                  at org.springframework.boot.SpringApplication.refreshContext(SpringApplication.java:389)
                  at org.springframework.boot.SpringApplication.run(SpringApplication.java:311)
                  at org.springframework.boot.test.context.SpringBootContextLoader.loadContext(SpringBootContextLoader.java:119)
                  at org.springframework.test.context.cache.DefaultCacheAwareContextLoaderDelegate.loadContextInternal(DefaultCacheAwareContextLoaderDelegate.java:99)
                  at org.springframework.test.context.cache.DefaultCacheAwareContextLoaderDelegate.loadContext(DefaultCacheAwareContextLoaderDelegate.java:117)
                  ... 24 common frames omitted
                  Caused by: java.lang.IllegalStateException: javax.websocket.server.ServerContainer not available
                  at org.springframework.util.Assert.state(Assert.java:73)
                  at org.springframework.web.socket.server.standard.ServerEndpointExporter.afterPropertiesSet(ServerEndpointExporter.java:106)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.invokeInitMethods(AbstractAutowireCapableBeanFactory.java:1837)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.initializeBean(AbstractAutowireCapableBeanFactory.java:1774)
                  ... 39 common frames omitted
                </p>
              </div>
              <div class="right">
                <div class="top" style="height: 45px;text-align: right">
                  2023-02-14 13:58:07
                </div>
                <div class="bottom" style="height: 45px;text-align: right">
                  <ul style="margin-top: 25px">
                    <li><a href="#">1次</a></li>
                    <li><a href="#">解决Bug</a></li>
                    <li><a href="#">编辑</a></li>
                    <li><a href="#">查看</a></li>
                    <li style="padding-right: 0"><a href="#">删除</a></li>
                  </ul>
                </div>
              </div>
            </div>

          </el-card>
        </li>
        <li>
          <el-card class="card" shadow="never">
            <div class="box">
              <div class="left">
                <p>Caused by: org.springframework.beans.factory.BeanCreationException: Error creating bean with name 'serverEndpointExporter' defined in class path resource [com/gxxw/video/modules/from/home/socket/WebSocketConfig.class]: Invocation of init method failed; nested exception is java.lang.IllegalStateException: javax.websocket.server.ServerContainer not available
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.initializeBean(AbstractAutowireCapableBeanFactory.java:1778)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.doCreateBean(AbstractAutowireCapableBeanFactory.java:593)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.createBean(AbstractAutowireCapableBeanFactory.java:515)
                  at org.springframework.beans.factory.support.AbstractBeanFactory.lambda$doGetBean$0(AbstractBeanFactory.java:320)
                  at org.springframework.beans.factory.support.DefaultSingletonBeanRegistry.getSingleton(DefaultSingletonBeanRegistry.java:222)
                  at org.springframework.beans.factory.support.AbstractBeanFactory.doGetBean(AbstractBeanFactory.java:318)
                  at org.springframework.beans.factory.support.AbstractBeanFactory.getBean(AbstractBeanFactory.java:199)
                  at org.springframework.beans.factory.support.DefaultListableBeanFactory.preInstantiateSingletons(DefaultListableBeanFactory.java:845)
                  at org.springframework.context.support.AbstractApplicationContext.finishBeanFactoryInitialization(AbstractApplicationContext.java:877)
                  at org.springframework.context.support.AbstractApplicationContext.refresh(AbstractApplicationContext.java:549)
                  at org.springframework.boot.SpringApplication.refresh(SpringApplication.java:742)
                  at org.springframework.boot.SpringApplication.refreshContext(SpringApplication.java:389)
                  at org.springframework.boot.SpringApplication.run(SpringApplication.java:311)
                  at org.springframework.boot.test.context.SpringBootContextLoader.loadContext(SpringBootContextLoader.java:119)
                  at org.springframework.test.context.cache.DefaultCacheAwareContextLoaderDelegate.loadContextInternal(DefaultCacheAwareContextLoaderDelegate.java:99)
                  at org.springframework.test.context.cache.DefaultCacheAwareContextLoaderDelegate.loadContext(DefaultCacheAwareContextLoaderDelegate.java:117)
                  ... 24 common frames omitted
                  Caused by: java.lang.IllegalStateException: javax.websocket.server.ServerContainer not available
                  at org.springframework.util.Assert.state(Assert.java:73)
                  at org.springframework.web.socket.server.standard.ServerEndpointExporter.afterPropertiesSet(ServerEndpointExporter.java:106)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.invokeInitMethods(AbstractAutowireCapableBeanFactory.java:1837)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.initializeBean(AbstractAutowireCapableBeanFactory.java:1774)
                  ... 39 common frames omitted
                </p>
              </div>
              <div class="right">
                <div class="top" style="height: 45px;text-align: right">
                  2023-02-14 13:58:07
                </div>
                <div class="bottom" style="height: 45px;text-align: right">
                  <ul style="margin-top: 25px">
                    <li><a href="#">1次</a></li>
                    <li><a href="#">已解决</a></li>
                    <li><a href="#">编辑</a></li>
                    <li><a href="#">查看</a></li>
                    <li style="padding-right: 0"><a href="#">删除</a></li>
                  </ul>
                </div>
              </div>
            </div>

          </el-card>
        </li>
        <li>
          <el-card class="card" shadow="never">
            <div class="box">
              <div class="left">
                <p>Caused by: org.springframework.beans.factory.BeanCreationException: Error creating bean with name 'serverEndpointExporter' defined in class path resource [com/gxxw/video/modules/from/home/socket/WebSocketConfig.class]: Invocation of init method failed; nested exception is java.lang.IllegalStateException: javax.websocket.server.ServerContainer not available
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.initializeBean(AbstractAutowireCapableBeanFactory.java:1778)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.doCreateBean(AbstractAutowireCapableBeanFactory.java:593)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.createBean(AbstractAutowireCapableBeanFactory.java:515)
                  at org.springframework.beans.factory.support.AbstractBeanFactory.lambda$doGetBean$0(AbstractBeanFactory.java:320)
                  at org.springframework.beans.factory.support.DefaultSingletonBeanRegistry.getSingleton(DefaultSingletonBeanRegistry.java:222)
                  at org.springframework.beans.factory.support.AbstractBeanFactory.doGetBean(AbstractBeanFactory.java:318)
                  at org.springframework.beans.factory.support.AbstractBeanFactory.getBean(AbstractBeanFactory.java:199)
                  at org.springframework.beans.factory.support.DefaultListableBeanFactory.preInstantiateSingletons(DefaultListableBeanFactory.java:845)
                  at org.springframework.context.support.AbstractApplicationContext.finishBeanFactoryInitialization(AbstractApplicationContext.java:877)
                  at org.springframework.context.support.AbstractApplicationContext.refresh(AbstractApplicationContext.java:549)
                  at org.springframework.boot.SpringApplication.refresh(SpringApplication.java:742)
                  at org.springframework.boot.SpringApplication.refreshContext(SpringApplication.java:389)
                  at org.springframework.boot.SpringApplication.run(SpringApplication.java:311)
                  at org.springframework.boot.test.context.SpringBootContextLoader.loadContext(SpringBootContextLoader.java:119)
                  at org.springframework.test.context.cache.DefaultCacheAwareContextLoaderDelegate.loadContextInternal(DefaultCacheAwareContextLoaderDelegate.java:99)
                  at org.springframework.test.context.cache.DefaultCacheAwareContextLoaderDelegate.loadContext(DefaultCacheAwareContextLoaderDelegate.java:117)
                  ... 24 common frames omitted
                  Caused by: java.lang.IllegalStateException: javax.websocket.server.ServerContainer not available
                  at org.springframework.util.Assert.state(Assert.java:73)
                  at org.springframework.web.socket.server.standard.ServerEndpointExporter.afterPropertiesSet(ServerEndpointExporter.java:106)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.invokeInitMethods(AbstractAutowireCapableBeanFactory.java:1837)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.initializeBean(AbstractAutowireCapableBeanFactory.java:1774)
                  ... 39 common frames omitted
                </p>
              </div>
              <div class="right">
                <div class="top" style="height: 45px;text-align: right">
                  2023-02-14 13:58:07
                </div>
                <div class="bottom" style="height: 45px;text-align: right">
                  <ul style="margin-top: 25px">
                    <li><a href="#">1次</a></li>
                    <li><a href="#">解决Bug</a></li>
                    <li><a href="#">编辑</a></li>
                    <li><a href="#">查看</a></li>
                    <li style="padding-right: 0"><a href="#">删除</a></li>
                  </ul>
                </div>
              </div>
            </div>

          </el-card>
        </li>
        <li>
          <el-card class="card" shadow="never">
            <div class="box">
              <div class="left">
                <p>Caused by: org.springframework.beans.factory.BeanCreationException: Error creating bean with name 'serverEndpointExporter' defined in class path resource [com/gxxw/video/modules/from/home/socket/WebSocketConfig.class]: Invocation of init method failed; nested exception is java.lang.IllegalStateException: javax.websocket.server.ServerContainer not available
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.initializeBean(AbstractAutowireCapableBeanFactory.java:1778)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.doCreateBean(AbstractAutowireCapableBeanFactory.java:593)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.createBean(AbstractAutowireCapableBeanFactory.java:515)
                  at org.springframework.beans.factory.support.AbstractBeanFactory.lambda$doGetBean$0(AbstractBeanFactory.java:320)
                  at org.springframework.beans.factory.support.DefaultSingletonBeanRegistry.getSingleton(DefaultSingletonBeanRegistry.java:222)
                  at org.springframework.beans.factory.support.AbstractBeanFactory.doGetBean(AbstractBeanFactory.java:318)
                  at org.springframework.beans.factory.support.AbstractBeanFactory.getBean(AbstractBeanFactory.java:199)
                  at org.springframework.beans.factory.support.DefaultListableBeanFactory.preInstantiateSingletons(DefaultListableBeanFactory.java:845)
                  at org.springframework.context.support.AbstractApplicationContext.finishBeanFactoryInitialization(AbstractApplicationContext.java:877)
                  at org.springframework.context.support.AbstractApplicationContext.refresh(AbstractApplicationContext.java:549)
                  at org.springframework.boot.SpringApplication.refresh(SpringApplication.java:742)
                  at org.springframework.boot.SpringApplication.refreshContext(SpringApplication.java:389)
                  at org.springframework.boot.SpringApplication.run(SpringApplication.java:311)
                  at org.springframework.boot.test.context.SpringBootContextLoader.loadContext(SpringBootContextLoader.java:119)
                  at org.springframework.test.context.cache.DefaultCacheAwareContextLoaderDelegate.loadContextInternal(DefaultCacheAwareContextLoaderDelegate.java:99)
                  at org.springframework.test.context.cache.DefaultCacheAwareContextLoaderDelegate.loadContext(DefaultCacheAwareContextLoaderDelegate.java:117)
                  ... 24 common frames omitted
                  Caused by: java.lang.IllegalStateException: javax.websocket.server.ServerContainer not available
                  at org.springframework.util.Assert.state(Assert.java:73)
                  at org.springframework.web.socket.server.standard.ServerEndpointExporter.afterPropertiesSet(ServerEndpointExporter.java:106)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.invokeInitMethods(AbstractAutowireCapableBeanFactory.java:1837)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.initializeBean(AbstractAutowireCapableBeanFactory.java:1774)
                  ... 39 common frames omitted
                </p>
              </div>
              <div class="right">
                <div class="top" style="height: 45px;text-align: right">
                  2023-02-14 13:58:07
                </div>
                <div class="bottom" style="height: 45px;text-align: right">
                  <ul style="margin-top: 25px">
                    <li><a href="#">1次</a></li>
                    <li><a href="#">解决Bug</a></li>
                    <li><a href="#">编辑</a></li>
                    <li><a href="#">查看</a></li>
                    <li style="padding-right: 0"><a href="#">删除</a></li>
                  </ul>
                </div>
              </div>
            </div>

          </el-card>
        </li>
        <li>
          <el-card class="card" shadow="never">
            <div class="box">
              <div class="left">
                <p>Caused by: org.springframework.beans.factory.BeanCreationException: Error creating bean with name 'serverEndpointExporter' defined in class path resource [com/gxxw/video/modules/from/home/socket/WebSocketConfig.class]: Invocation of init method failed; nested exception is java.lang.IllegalStateException: javax.websocket.server.ServerContainer not available
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.initializeBean(AbstractAutowireCapableBeanFactory.java:1778)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.doCreateBean(AbstractAutowireCapableBeanFactory.java:593)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.createBean(AbstractAutowireCapableBeanFactory.java:515)
                  at org.springframework.beans.factory.support.AbstractBeanFactory.lambda$doGetBean$0(AbstractBeanFactory.java:320)
                  at org.springframework.beans.factory.support.DefaultSingletonBeanRegistry.getSingleton(DefaultSingletonBeanRegistry.java:222)
                  at org.springframework.beans.factory.support.AbstractBeanFactory.doGetBean(AbstractBeanFactory.java:318)
                  at org.springframework.beans.factory.support.AbstractBeanFactory.getBean(AbstractBeanFactory.java:199)
                  at org.springframework.beans.factory.support.DefaultListableBeanFactory.preInstantiateSingletons(DefaultListableBeanFactory.java:845)
                  at org.springframework.context.support.AbstractApplicationContext.finishBeanFactoryInitialization(AbstractApplicationContext.java:877)
                  at org.springframework.context.support.AbstractApplicationContext.refresh(AbstractApplicationContext.java:549)
                  at org.springframework.boot.SpringApplication.refresh(SpringApplication.java:742)
                  at org.springframework.boot.SpringApplication.refreshContext(SpringApplication.java:389)
                  at org.springframework.boot.SpringApplication.run(SpringApplication.java:311)
                  at org.springframework.boot.test.context.SpringBootContextLoader.loadContext(SpringBootContextLoader.java:119)
                  at org.springframework.test.context.cache.DefaultCacheAwareContextLoaderDelegate.loadContextInternal(DefaultCacheAwareContextLoaderDelegate.java:99)
                  at org.springframework.test.context.cache.DefaultCacheAwareContextLoaderDelegate.loadContext(DefaultCacheAwareContextLoaderDelegate.java:117)
                  ... 24 common frames omitted
                  Caused by: java.lang.IllegalStateException: javax.websocket.server.ServerContainer not available
                  at org.springframework.util.Assert.state(Assert.java:73)
                  at org.springframework.web.socket.server.standard.ServerEndpointExporter.afterPropertiesSet(ServerEndpointExporter.java:106)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.invokeInitMethods(AbstractAutowireCapableBeanFactory.java:1837)
                  at org.springframework.beans.factory.support.AbstractAutowireCapableBeanFactory.initializeBean(AbstractAutowireCapableBeanFactory.java:1774)
                  ... 39 common frames omitted
                </p>
              </div>
              <div class="right">
                <div class="top" style="height: 45px;text-align: right">
                  2023-02-14 13:58:07
                </div>
                <div class="bottom" style="height: 45px;text-align: right">
                  <ul style="margin-top: 25px">
                    <li><a href="#">1次</a></li>
                    <li><a href="#">解决Bug</a></li>
                    <li><a href="#">编辑</a></li>
                    <li><a href="#">查看</a></li>
                    <li style="padding-right: 0"><a href="#">删除</a></li>
                  </ul>
                </div>
              </div>
            </div>

          </el-card>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "BugRecord"
}
</script>

<style scoped>
.cu {
  float: right;
  padding: 10px 10px 20px;
  margin-left: 10px;
  width: 1000px;
  background-color: #ffffff;
}
.utils {
  display: inline-block;
  width: 1000px;
}
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000000;
}
.card {
  width: 980px;
  margin-bottom: 10px;
}
.card:hover {
  background-color: #F0F2F5;
}
p {
  /*text-indent: 2em;*/
  line-height: 1.5;
}
.status li {
  padding-right: 10px;
  display: inline-block;
  list-style: none;
}
.status a {
  color: #999;
}

.utils .left {
  overflow: hidden;
  display: inline-block;
  width: 600px;
  height: 95px;
}
.utils .right {
  float: right;
  overflow: hidden;
  display: inline-block;
  width: 300px;
  height: 95px;
}
.bottom li {
  padding-right: 25px;
  display: inline-block;
}
a:hover {
  color: #1890FF;
}
</style>
