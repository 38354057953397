<template>
  <div class="ps">
    <ul>
      <li>
        <span style="display: inline-block">封面：</span>
        <el-upload
            ref="upload"
            style="display: inline-block;vertical-align: middle;margin-left: 20px"
            class="upload-demo"
            drag
            action="string"
            :limit="1"
            :on-remove="handleRemove"
            :on-change="handleChange"
            :http-request="publish"
            :auto-upload = "false"
            :multiple = "false">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
      </li>
      <li style="margin-top: 20px">
        <span style="display: inline-block">摘要：</span>
        <el-input
            style="display: inline-block;width: 400px;margin-left: 20px;vertical-align: middle;margin-right: 10px"
            type="textarea"
            placeholder="请输入内容"
            v-model="textarea"
            maxlength="100"
            show-word-limit
            autosize
        >
        </el-input>
        <el-button size="mini" round @click="extract">一键提取</el-button>
      </li>
      <li style="margin-top: 20px">
        <span style="display: inline-block">分类：</span>
        <el-select style="margin-left: 20px" v-model="value" placeholder="请选择">
          <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </li>
      <li style="margin-top: 20px">
        <span style="display: inline-block">标签：</span>
        <el-select style="width: 500px;margin-left: 20px" v-model="value1" multiple placeholder="请选择">
          <el-option
              v-for="item in options1"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </li>
      <li style="margin-top: 20px">
        <span style="display: inline-block">文章类型：</span>
        <el-radio-group style="margin-left: 20px" v-model="radio">
          <el-radio :label="3">原创</el-radio>
          <el-radio :label="6">转载</el-radio>
          <el-radio :label="9">翻译</el-radio>
        </el-radio-group>
      </li>
      <li style="margin-top: 20px;">
        <span style="display: inline-block">发布类型：</span>
        <el-radio-group style="margin-left: 20px" v-model="radio1">
          <el-radio :label="3">所有人可见</el-radio>
          <el-radio :label="6">仅自己可见</el-radio>
<!--          <el-radio :label="9">粉丝可见</el-radio>-->
        </el-radio-group>
      </li>
    </ul>
    <el-divider></el-divider>
    <div class="finish">
      <el-button round @click="test('DRAFT')">保存草稿</el-button>
<!--      <el-button style="margin-left: 50px" type="primary" round @click="publish('PUBLISHING')">发布文章</el-button>-->
      <el-button style="margin-left: 50px" type="primary" round @click="test('PUBLISHING')">发布文章</el-button>
    </div>
  </div>
</template>

<script>
import {getArticleTypes, getArticleTags, publishArticle, getArtcleById, getTagByArticleId, updateArticleById} from "@/api/myArticle";
import bus from "@/store/bus";
import { getToken } from "@/utils/tokenUtil";
import {uploadfile} from "@/api/myFile";

export default {
  name: "PublishSetting",
  data() {
    return {
      initArticleId: '',
      aType: '',
      pType: '',
      file: '',
      as: '',
      textarea: '',
      options: [],
      options1: [],
      value: '',
      value1: [],
      radio: 3,
      radio1: 3,
      action: 'http://39.103.60.183:8081/myFile/api/v1/uploadFile',
      headers: {
        authorization: getToken('token')
      }
    }
  },
  methods: {
    handleChange (file) {
      this.file = file;
      this.$notify({
        title: '成功',
        message: '文件上传成功',
        type: 'success'
      });
      // console.log(this.fileUrl)
    },
    handleRemove () {
      this.$notify({
        title: '成功',
        message: '文件移除成功',
        type: 'success'
      });
      // console.log(file, fileList);
    },
    // uploadFile (data) {
    //   const formData = new FormData()
    //   formData.append('file', data.file)
    //   uploadfile(formData).then(
    //       res => {
    //         console.log('uploadfile',res)
    //       }
    //   )
    //   // this.formData.append("file", file.file);
    //   // console.log(file.file, "sb2");
    // },
    extract() {
      // console.log(this.$store.state.articletext)
      var text = this.$store.state.articletext
      text = text.replace(/\s*/g, "");  //去掉空格
      text = text.replace(/<[^>]+>/g, ""); //去掉所有的html标记
      text = text.replace(/↵/g, "");     //去掉所有的↵符号
      text = text.replace(/[\r\n]/g, "") //去掉回车换行
      text = text.replace(/&nbsp;/g, "") //去掉空格
      if(text === null || text === '') {
        this.$notify({
          title: '警告',
          message: '文章内容为空，无法提取',
          type: 'warning'
        });
      } else {
        this.textarea = text.substring(0,100)
      }
    },
    test(data){
      // console.log(data)
      this.as = data
      // 获取参数
      const title = this.$store.state.articletitle  //文章标题
      const content = this.$store.state.articlecontent  //文章内容
      // console.log('content',content)
      const articleAbstract = this.textarea //文章摘要
      var articleType  //文章类型
      if(this.radio === 3) {
        articleType = 'ORIGINAL'
        this.aType = 'ORIGINAL'
      } else if(this.radio === 6) {
        articleType = 'REPRINT'
        this.aType = 'REPRINT'
      } else {
        articleType = 'TRANSLATE'
        this.aType = 'TRANSLATE'
      }
      var publishType //发布类型
      if(this.radio1 === 3) {
        publishType = 'OWNER'
        this.pType = 'OWNER'
      } else if(this.radio1 === 6) {
        publishType = 'ONESELF'
        this.pType = 'ONESELF'
      } else {
        publishType = 'FANS'
        this.pType = 'FANS'
      }
      const typeId = this.value //文章分类
      const articleTags = this.value1 //文章标签

      //参数校验
      if(title === null || title === '') {
        this.$notify({
          title: '警告',
          message: '文章标题不能为空',
          type: 'warning'
        });
        return;
      }
      if(content === null || content === '' || content === '<p><br></p>') {
        this.$notify({
          title: '警告',
          message: '文章内容不能为空',
          type: 'warning'
        });
        return;
      }
      if(this.file === null || this.file === '') {
        this.$notify({
          title: '警告',
          message: '文章封面不能为空',
          type: 'warning'
        });
        return;
      }
      if(articleAbstract === null || articleAbstract === '') {
        this.$notify({
          title: '警告',
          message: '文章摘要不能为空',
          type: 'warning'
        });
        return;
      }
      if(articleType === null || articleType === '') {
        this.$notify({
          title: '警告',
          message: '文章类型不能为空',
          type: 'warning'
        });
        return;
      }
      if(publishType === null || publishType === '') {
        this.$notify({
          title: '警告',
          message: '文章发布类型不能为空',
          type: 'warning'
        });
        return;
      }
      if(typeId === null || typeId === '') {
        this.$notify({
          title: '警告',
          message: '文章分类不能为空',
          type: 'warning'
        });
        return;
      }
      if(articleTags.length === 0 || articleTags === null || articleTags === '') {
        this.$notify({
          title: '警告',
          message: '文章标签不能为空',
          type: 'warning'
        });
        return;
      }
      if(this.as === null || this.as === '') {
        this.$notify({
          title: '警告',
          message: '文章状态不能为空',
          type: 'warning'
        });
        return;
      }
      // console.log(this.initArticleId)
      if(this.initArticleId === null || this.initArticleId === '' || this.initArticleId === undefined) {
        this.$refs.upload.submit()
      } else {
        this.updateArticle()
      }

    },
    updateArticle() {
      const article = {
        id: this.initArticleId,
        title: this.$store.state.articletitle,
        content: this.$store.state.articlecontent,
        articleAbstract: this.textarea,
        articleType: this.aType,
        publishType: this.pType,
        typeId: this.value,
        articleTags: this.value1,
        articleStatus: this.as,
      }
      updateArticleById(article).then(
          res => {
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
            //清空页面输入框内容
            bus.$emit("cleartitleandcontent",true)
            this.textarea = ''
            this.value = ''
            this.value1 = []
            this.radio = 3
            this.radio1 = 3
            this.file = ''
            this.initArticleId = ''
            this.$refs.upload.clearFiles()
          }
      )
    },
    publish(param) {
      const formData = new FormData()
      formData.append('file', param.file)
      uploadfile(formData).then(
          res => {
            //封装参数
            const article = {
              title: this.$store.state.articletitle,
              content: this.$store.state.articlecontent,
              articleAbstract: this.textarea,
              articleType: this.aType,
              publishType: this.pType,
              typeId: this.value,
              articleTags: this.value1,
              articleStatus: this.as,
              cover: res.data
            }
            publishArticle(article).then(
                res => {
                  // console.log(res)
                  this.$notify({
                    title: '成功',
                    message: res.data,
                    type: 'success'
                  });
                  //清空页面输入框内容
                  bus.$emit("cleartitleandcontent",true)
                  this.textarea = ''
                  this.value = ''
                  this.value1 = []
                  this.radio = 3
                  this.radio1 = 3
                  this.file = ''
                  this.initArticleId = ''
                  this.$refs.upload.clearFiles()
                }
            )
            // console.log('uploadfile',res)
          }
      )

    },
  },
  // activated() {
  //   getArticleTypes().then(
  //       res => {
  //         // console.log(res.data)
  //         this.options = res.data
  //       }
  //   )
  //   getArticleTags().then(
  //       res => {
  //         // console.log(res.data)
  //         this.options1 = res.data
  //       }
  //   )
  //   this.initArticleId = this.$route.params.id
  //   if(this.initArticleId != null && this.initArticleId != '')
  //   getArtcleById(this.initArticleId).then(
  //       res => {
  //         // console.log(res.data)
  //         this.file = res.data.cover
  //         this.textarea = res.data.articleAbstract
  //         this.value = res.data.typeId
  //         getTagByArticleId(res.data.id).then(
  //             res => {
  //               // console.log(res)
  //               for (let i=0; i<res.data.length; i++) {
  //                 // console.log(res.data[i].id)
  //                 this.value1.push(res.data[i].id)
  //               }
  //               // console.log(this.value1)
  //             }
  //         )
  //         if(res.data.articleType === 'ORIGINAL') {
  //           this.radio = 3
  //         } else if(res.data.articleType === 'REPRINT') {
  //           this.radio = 6
  //         } else {
  //           this.radio = 9
  //         }
  //         if(res.data.publishType === 'OWNER') {
  //           this.radio1 = 3
  //         } else if(res.data.publishType === 'ONESELF') {
  //           this.radio1 = 6
  //         } else {
  //           this.radio1 = 9
  //         }
  //       }
  //   )
  // },
  // deactivated() {
  //   if(this.initArticleId != null || this.initArticleId != '') {
  //     //清空页面输入框内容
  //     bus.$emit("cleartitleandcontent",true)
  //     this.textarea = ''
  //     this.value = ''
  //     this.value1 = []
  //     this.radio = 3
  //     this.radio1 = 3
  //     this.file = ''
  //     this.initArticleId = ''
  //     this.$refs.upload.clearFiles()
  //   }
  // },
  beforeDestroy() {
    // console.log(789)
    if(this.initArticleId != null || this.initArticleId != '') {
      //清空页面输入框内容
      bus.$emit("cleartitleandcontent",true)
      this.textarea = ''
      this.value = ''
      this.value1 = []
      this.radio = 3
      this.radio1 = 3
      this.file = ''
      this.initArticleId = ''
      this.$refs.upload.clearFiles()
    }
  },
  created() {
    getArticleTypes().then(
        res => {
          // console.log(res.data)
          this.options = res.data
        }
    )
    getArticleTags().then(
        res => {
          // console.log(res.data)
          this.options1 = res.data
        }
    )
    this.initArticleId = this.$route.params.id
    if(this.initArticleId != null && this.initArticleId != '')
      getArtcleById(this.initArticleId).then(
          res => {
            // console.log(res.data)
            this.file = res.data.cover
            this.textarea = res.data.articleAbstract
            this.value = res.data.typeId
            getTagByArticleId(res.data.id).then(
                res => {
                  // console.log(res)
                  for (let i=0; i<res.data.length; i++) {
                    // console.log(res.data[i].id)
                    this.value1.push(res.data[i].id)
                  }
                  // console.log(this.value1)
                }
            )
            if(res.data.articleType === 'ORIGINAL') {
              this.radio = 3
            } else if(res.data.articleType === 'REPRINT') {
              this.radio = 6
            } else {
              this.radio = 9
            }
            if(res.data.publishType === 'OWNER') {
              this.radio1 = 3
            } else if(res.data.publishType === 'ONESELF') {
              this.radio1 = 6
            } else {
              this.radio1 = 9
            }
          }
      )
  }
}
</script>

<style scoped>
.ps {
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  background-color: white;
}
a {
  text-decoration: none;
  color: #000000;
}
li {
  list-style: none;
}
ul {
  width: 730px;
  margin-left: 50px;
}
.finish {
  text-align: center;
}
</style>
