<template>
  <div class="main">
    <ShowType></ShowType>
    <HotArticle></HotArticle>
    <ArticleList></ArticleList>
  </div>
</template>

<script>
import ShowType from "@/components/homepage/body/ShowType";
import HotArticle from "@/components/homepage/body/HotArticle";
import ArticleList from "@/components/homepage/body/ArticleList";
export default {
  name: "HomePage",
  components: {ArticleList, HotArticle, ShowType}
}
</script>

<style scoped>
.main {
  padding: 10px;
  background-color: #F0F2F5;
  width: 100%;
}
</style>
