<template>
  <div class="pa">
    <div class="left">
      <ArticleSidebar></ArticleSidebar>
    </div>
    <div class="right">
<!--      <keep-alive :exclude="excludePage">-->
        <router-view></router-view>
<!--      </keep-alive>-->

    </div>

  </div>
</template>

<script>
import ArticleSidebar from "@/components/framercenter/ArticleSidebar";
import ArticleEditor from "@/components/personalcenter/typearea/show/commonutils/ArticleEditor.vue";
import PublishArticle from "@/components/framercenter/publishmanage/PublishArticle.vue";
export default {
  name: "FramerCenter",
  data() {
    return {
      includePage: ['PublishArticle'],
      excludePage: ['ArticleEditor']
    }
  },
  computed: {
    PublishArticle() {
      return PublishArticle
    },
    ArticleEditor() {
      return ArticleEditor
    }
  },
  components: { ArticleSidebar,}
}
</script>

<style scoped>
.pa {
  padding: 10px;
  background-color: #F0F2F5;
  width: 100%;
  overflow: hidden;
}
.left {
  width: 240px;
  float: left;
}
.right {
  width: 1249px;
  float: left;
  margin-left: 10px;
}
</style>
