<template>
  <div id="app">
    <NavigationBar></NavigationBar>
<!--    <keep-alive include="FramerCenter">-->
      <router-view></router-view>
<!--    </keep-alive>-->
    <MLogin></MLogin>
    <MRegister></MRegister>
  </div>
</template>

<script>
import NavigationBar from "@/components/header/NavigationBar";
import MLogin from "@/components/login/MLogin.vue";
import MRegister from "@/components/login/MRegister.vue";
export default {
  name: 'App',
  components: {
    MRegister,
    MLogin,
    NavigationBar
  },

}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /*background-color: #F0F2F5;*/
}
</style>
