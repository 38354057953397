<template>
  <div class="am">
    <div class="top">
      <ul>
        <li style="margin-right: 30px;">文章</li>
      </ul>
      <el-input size="medium" style="width: 300px;margin-left: 500px" placeholder="请输入内容" v-model="input3" class="input-with-select">
        <el-button slot="append" icon="el-icon-search" @click="getAllArticles('all')"></el-button>
      </el-input>
    </div>
    <hr>
    <div class="body">
      <div class="bar">
        <ul>
          <el-button type="text" @click="getAllArticles('all')"><li>全部（{{allCount}}）</li></el-button>
          <el-button type="text" @click="getAllArticles('published')"><li>已发布（{{publishedCount}}）</li></el-button>
          <el-button type="text" @click="getAllArticles('audit')"><li>审核中（{{auditCount}}）</li></el-button>
          <el-button type="text" @click="getAllArticles('nopass')"><li>未通过（{{nopassCount}}）</li></el-button>
          <el-button type="text" @click="getAllArticles('allsee')"><li>全部可见（{{allseeCount}}）</li></el-button>
          <el-button type="text" @click="getAllArticles('mesee')"><li>仅我可见（{{meseeCount}}）</li></el-button>
          <el-button type="text" @click="getAllArticles('draft')"><li>草稿箱（{{draftCount}}）</li></el-button>
        </ul>
      </div>
      <div class="list">
        <ul class="second">
          <li v-for="article in articleList" :key="article.id">
            <div class="box">
              <div class="cover" style="display: inline-block">
                <div class="showhand" @click="lookArticle(article.id)">
                  <img style="width: 160px;height: 90px;display: inline-block" :src="article.cover">
                </div>
              </div>
              <div class="information" style="display: inline-block;width: 1020px;float: right">
                <div class="showTop" style="width: 100%;height: 30px">
                  <div class="showhand" style="font-weight: 500;font-size: 16px" @click="lookArticle(article.id)">{{ article.title }}</div>
                  <div style="display: inline-block;float: right;font-size: 14px;color: #999">{{ article.createTime }}</div>
                </div>
                <div class="showCenter" style="width: 100%;height: 30px">
                  <el-tag size="mini">
                    <span v-if="article.articleType === 'ORIGINAL'">原创</span>
                    <span v-if="article.articleType === 'REPRINT'">转载</span>
                    <span v-if="article.articleType === 'TRANSLATE'">翻译</span>
                  </el-tag>
                  <el-tag size="mini" type="info" style="margin-left: 10px">
                    <span v-if="article.publishType === 'OWNER'">所有人可见</span>
                    <span v-if="article.publishType === 'ONESELF'">仅自己可见</span>
                    <span v-if="article.publishType === 'FANS'">粉丝可见</span>
                  </el-tag>
                </div>
                <div class="showBottom" style="width: 100%;height: 30px">
                  <span style="color: #999aaa;font-size: 14px;margin-right: 10px">
                    <span v-if="article.lookNumber != null">阅读 {{article.lookNumber}}</span>
                    <span v-else>阅读 0</span>
                  </span>
                  <span style="color: #999aaa;font-size: 14px;margin-right: 10px">
                    <span v-if="article.commentNumber != null">评论 {{ article.commentNumber }}</span>
                    <span v-else>评论 0</span>
                  </span>
                  <span style="color: #999aaa;font-size: 14px;margin-right: 10px">
                    <span v-if="article.collectNumber != null">收藏 {{ article.collectNumber }}</span>
                    <span v-else>收藏 0</span>
                  </span>
                  <span style="color: #999aaa;font-size: 14px;margin-right: 10px">
                    <span v-if="article.likeNumber != null">点赞 {{ article.likeNumber }}</span>
                    <span v-else>点赞 0</span>
                  </span>
                  <div style="display: inline-block;float: right">
                    <span style="margin-left: 20px"><el-button type="text" @click="bianji(article.id)">编辑</el-button></span>
                    <span style="margin-left: 20px"><el-button type="text" @click="lookArticle(article.id)">浏览</el-button></span>
                    <span style="margin-left: 20px"><el-button type="text" @click="delArticle(article.id)">删除</el-button></span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {articleManageList, getCount, delArtcleById} from "@/api/myArticle";

export default {
  name: "ArticleManage",
  data() {
    return {
      value: new Date(),
      circleUrl: "https://pic3.zhimg.com/v2-58d652598269710fa67ec8d1c88d8f03_r.jpg?source=1940ef5c",
      articleList: [],
      allCount: '',
      allseeCount: '',
      auditCount: '',
      draftCount: '',
      meseeCount: '',
      nopassCount: '',
      publishedCount: '',
      input3: ''
    }
  },
  methods: {
    bianji(id) {
      // this.$store.commit('bianjiArticleId',id)
      this.$router.push({
        name: 'publisharticle',
        params: {
          id: id
        }
      })
    },
    lookArticle(id) {
      this.$router.push({
        name: 'detailpage',
        params: {
          id:id
        }
      })
    },
    getArticleCount() {
      getCount().then(
          res => {
            // console.log(res)
            this.allCount = res.data.allCount
            this.allseeCount = res.data.allseeCount
            this.auditCount = res.data.auditCount
            this.draftCount = res.data.draftCount
            this.meseeCount = res.data.meseeCount
            this.nopassCount = res.data.nopassCount
            this.publishedCount = res.data.publishedCount
          }
      )
    },
    getAllArticles(status) {
      const title = this.input3
      articleManageList(status,title).then(
          res => {
            // console.log(res)
            this.articleList = res.data
            this.input3 = ''
          }
      )
    },
    delArticle(id) {

      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delArtcleById(id).then(
            res => {
              this.getAllArticles()
              this.getArticleCount()
              this.$notify({
                title: '成功',
                message: res.data,
                type: 'success'
              });
            }
        )
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  },
  created() {
    this.getAllArticles()
    this.getArticleCount()
  }
}
</script>

<style scoped>
.box {
  padding: 10px 0 10px 0;
  width: 1200px;
  border-style: none none solid none;
  border-width: 1px;
  border-color: #ddd;
}
.am {
  width: 100%;
  background-color: #FFFFFF;
}
.top {
  padding: 0 20px 0 20px;
  width: 100%;
  height: 56px;
  line-height: 56px;
}
.list {
  padding: 10px 20px 10px 30px;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  color: #1E80FF;
}
li {
  list-style: none;
  display: inline-block;
}
.top ul {
  float: left;
  width: 400px;
}
.bar {
  padding: 0 20px 0 20px;
}
.bar ul {
  height: 56px;
  line-height: 56px;

}
.bar li {
  padding: 0 10px 0 10px;
  color: #86909C;
}
.bar li:hover {
  color: #1E80FF;
}

.second {
  display: inline-block;
  width: 1000px;
}
.second li {
  list-style: none;
}
p {
  /*text-indent: 2em;*/
  line-height: 1.5;
}
.three li {
  padding-right: 10px;
  display: inline-block;
  list-style: none;
}
.showhand:hover {
  cursor:pointer;
}
</style>
