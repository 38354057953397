
export function getToken(name) {
    return localStorage.getItem(name)
}

export function setToken(token) {
    localStorage.setItem('token',token)
}

export function removeToken() {
    localStorage.removeItem('token')
}