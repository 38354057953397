<template>
  <div class="ic">
    <div class="demo-basic--circle">
      <div class="block">
        <el-avatar :size="50" :src="headPortrait"></el-avatar>
      </div>
      <h4 style="display: inline-block;margin-left: 10px;font-weight: 400">{{ nickName }}</h4>
    </div>
    <ul>
      <li>
        <div class="box">
          <div class="top">{{ articleSum }}</div>
          <div  class="bottom">原创</div>
        </div>
      </li>
      <li>
        <div class="box">
          <div class="top">{{ funsNum }}</div>
          <div class="bottom">粉丝</div>
        </div>
      </li>
      <li>
        <div class="box">
          <div class="top">{{ likeSum }}</div>
          <div class="bottom">获赞</div>
        </div>
      </li>
      <li>
        <div class="box">
          <div class="top">{{ commentSum }}</div>
          <div class="bottom">评论</div>
        </div>
      </li>
      <li>
        <div class="box">
          <div class="top">{{ collectSum }}</div>
          <div class="bottom">收藏</div>
        </div>
      </li>
    </ul>
    <div class="bs">
<!--      <el-button style="width: 120px" size="medium" round>私信</el-button>-->
<!--      <el-button style="width: 120px" size="medium" round>关注</el-button>-->
<!--      <div class="hand diybutton" style="">-->
<!--        私信-->
<!--      </div>-->
      <div @click="attention" v-if="attentionTag" class="hand diybutton" style="margin-left: 10px;background-color: #ECF5FF;color: #1E80FF;">
        取消关注
      </div>
      <div @click="attention" v-else class="hand diybutton" style="margin-left: 10px">
        关注
      </div>

    </div>

  </div>
</template>

<script>
import {isAttention, attentionUser} from "@/api/attention";
import {getArtcleById,getUserAll} from "@/api/myArticle";

export default {
  name: "InformationCard",
  data() {
    return {
      circleUrl: "https://img.zcool.cn/community/01c8f15aeac135a801207fa16836ae.jpg@1280w_1l_2o_100sh.jpg",
      attentionTag: false,
      beFollowerId: '',
      headPortrait: '',
      nickName: '',
      articleSum: 0,
      funsNum: 0,
      likeSum: 0,
      commentSum: 0,
      collectSum: 0
    }
  },
  methods: {
    attention() {
      attentionUser(this.beFollowerId).then(
          res => {
            // console.log(res)
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success'
            });
            this.ifAttention(this.beFollowerId)
          }
      )
    },
    ifAttention(beFollowerId) {
      isAttention(beFollowerId).then(
          res => {
            // console.log(res)
            this.attentionTag = res.data
          }
      )
    },
    getUserCard(id) {
      getUserAll(id).then(
          res => {
            // console.log(res)
            this.headPortrait = res.data.headPortrait
            this.nickName = res.data.nickName
            this.articleSum = res.data.articleCount
            this.funsNum = res.data.funsNum
            this.likeSum = res.data.likeSum
            this.commentSum = res.data.commentSum
            this.collectSum = res.data.collectSum
          }
      )
    }
  },
  created() {
    const articleId = this.$route.params.id
    getArtcleById(articleId).then(
        res => {
          // console.log(res)
          this.beFollowerId = res.data.userId
          this.ifAttention(res.data.userId)
          this.getUserCard(res.data.userId)
        }
    )
  }
}
</script>

<style scoped>
.ic {
  overflow: hidden;
  background-color: white;
  padding: 10px;
  border-radius: 4px;
}
.block {
  display: inline-block;
  vertical-align: middle;
}
li {
  padding: 0 10px 0 10px;
  display: inline-block;
  list-style: none;
  text-align: center;
  /*line-height: 48px;*/
  height: 70px;
}
ul {
  margin-top: 10px;
  height: 70px;
}
.top {
  height: 35px;
  line-height: 35px;
}
.bottom {
  height: 35px;
  line-height: 35px;
}
.bs {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.hand:hover {
  cursor: pointer;
  background-color: #ECF5FF;
  color: #1E80FF;
}
.diybutton {
  width: 120px;
  height: 35px;
  border-width: 1px;
  border-style:solid;
  border-color: #DCDFE6;
  border-radius: 20px;
  line-height: 35px;
  text-align: center;
  color: #606266;
  font-size: 14px;
  display: inline-block
}
</style>
