<template>
  <div class="dp" :key="num">
<!--      <div>-->
<!--          &lt;!&ndash; 添加一个按钮，用于手动触发请求，以及显示请求结果 &ndash;&gt;-->
<!--          <button @click="requestData">手动请求数据</button>-->
<!--          <p v-if="responseData">接口返回的数据：{{ responseData }}</p>-->
<!--      </div>-->
    <div class="left">
      <InformationCard></InformationCard>
<!--      <SearchInput></SearchInput>-->
      <HotRankingList></HotRankingList>
<!--      <TypeList></TypeList>-->
      <NewArticle></NewArticle>
    </div>
    <div class="center">
      <DetailArticle></DetailArticle>
      <ArticleComment></ArticleComment>
    </div>
    <div class="right">
      <ButtonGroup></ButtonGroup>
    </div>
  </div>
</template>

<script>
import InformationCard from "@/components/detailpage/left/InformationCard";
import HotRankingList from "@/components/detailpage/left/HotRankingList";
import NewArticle from "@/components/detailpage/left/NewArticle";
import ButtonGroup from "@/components/detailpage/ringht/ButtonGroup";
import DetailArticle from "@/components/detailpage/center/DetailArticle";
import ArticleComment from "@/components/detailpage/center/ArticleComment";
// import {getTest} from "@/api/myArticle";
export default {
  name: "DetailPage",
  data() {
    return {
      num: 1,
        // responseData: null, // 用于存储接口返回的数据
    }
  },
    // created() {
    //     // 使用定时器每隔5秒触发一次请求
    //     setInterval(this.requestData, 5000);
    // },
    // methods: {
    //     requestData() {
    //         console.log('请求执行')
    //         // 使用axios发送请求到后台接口
    //         getTest()
    //             .then((response) => {
    //                 this.responseData = response.data;
    //
    //                 if (this.responseData && this.responseData.length > 0) {
    //                     console.log('播放音频')
    //                     this.playAudio();
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error('请求失败：', error);
    //             });
    //     },
    //     playAudio() {
    //         const audioUrl = `${process.env.BASE_URL}audio/yj.mp3`;
    //         const audio = new Audio(audioUrl);
    //         audio.play();
    //     },
    // },
  watch: {
    '$route'() {
      // this.$router.go(0)
      // this.$forceUpdate()
      this.num = Math.random()
    }
  },
  components: {
    ArticleComment,
    DetailArticle, ButtonGroup, NewArticle, HotRankingList, InformationCard},
}
</script>

<style scoped>
.dp {
  overflow: hidden;
  background-image: url("https://pic.52112.com/180308/180308_94/4S0JMaqsnM_small.jpg");
  width: 100%;
  padding: 10px;
}
.left {
  float: left;
  margin-left: 70px;
  width: 300px;
}
.center {
  /*padding: 10px 24px 16px;*/
  /*border-radius: 4px;*/
  float: left;
  margin-left: 10px;
  width: 1010px;
  /*height: 600px;*/
  /*background-color: white;*/
}
.right {
  padding: 10px;
  float: left;
  margin-left: 10px;
  width: 60px;
  /*height: 300px;*/
  /*background-color: white;*/
  /*text-align: center;*/
}
</style>
